import {AsyncPipe} from '@angular/common'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {BehaviorSubject, Subscription} from 'rxjs'
import {filter, first} from 'rxjs/operators'
import {BuildingTypes, KalpBuilding} from '../../model/kalp-building'
import {KalpLoan} from '../../model/kalp-loan'
import {DataService, SaveData, SaveDataLoan} from '../../services/data.service'
import {KalpService, ObjectTypes, Scenarios} from '../../services/kalp.service'
import {ProposalService} from '../../services/proposal.service'
import {LoanFilterPipe} from './loan-filter.pipe'
import {LoanComponent} from './loan/loan.component'

export interface Property {
  name: string
  type: BuildingTypes
  id: string
}

@Component({
  selector: 'spb-existing-loan',
  templateUrl: './existing-loan.component.html',
  styleUrls: ['./existing-loan.component.scss'],
  imports: [LoanComponent, MatButton, AsyncPipe, LoanFilterPipe]
})
export class ExistingLoanComponent implements OnInit, OnDestroy {

  public loans$: BehaviorSubject<KalpLoan[]> = new BehaviorSubject<KalpLoan[]>([])

  public properties: Property[] = []

  public conditions = false

  /**
   * If we should show the terms select box on the loan.
   * It should be shown when scneario is "Increase" or "Omsättning".
   */
  public showTerms = false

  private data$ = new Subscription()

  private scenario$ = new Subscription()

  private scenario: Scenarios = Scenarios.Purchase

  private building$ = new Subscription()

  constructor(
    private kalpService: KalpService,
    private dataService: DataService,
    private proposalService: ProposalService
  ) {
  }


  public ngOnInit(): void {
    this.updateBuildings()
    this.scenario$ = this.kalpService.scenarioChange$.pipe(
      filter((scenarios: Scenarios | null) => scenarios !== null)
    ).subscribe({
      next: (scenario: Scenarios | null) => {
        this.conditions = scenario === Scenarios.Conditions

        this.showTerms =
          [Scenarios.Increase, Scenarios.Conditions, Scenarios.Transfer].indexOf(scenario as Scenarios) !== -1
        this.scenario = scenario as Scenarios
      }
    })

    this.data$ = this.dataService.data$
      .subscribe({
        next: (data: SaveData | null) => {
          if (data) {
            const loans = Object.values(data.loans)
              .map((ls: SaveDataLoan[]) =>
                ls.map((subLoan: any) => {
                  const loan = new KalpLoan(0)
                  loan.setFromInput(subLoan)
                  return loan
                }))
            this.loans$.next(loans[1].concat(loans[0]))
          }
          this.changes({} as any) // we must emit changes
        }
      })
  }

  public ngOnDestroy(): void {
    this.data$.unsubscribe()
    this.scenario$.unsubscribe()
    this.building$.unsubscribe()
  }

  /**
   * Removes a loan based on ID
   */
  public removeLoan(id: string): void {
    this.loans$.next(this.loans$.value.filter((loan: KalpLoan) => loan.id !== id))
    this.changes(undefined)
  }

  public addLoan(isBlancoLoan: boolean): void {
    const loan = new KalpLoan(0)
    if (isBlancoLoan) {
      loan.interestPercent = 0.08
      loan.isBlancoLoan = true
    }

    this.loans$.next(this.loans$.value.concat([loan]))
    this.changes(undefined)
  }


  public changes(loan: KalpLoan | undefined): void {
    this.loans$.pipe(first())
      .subscribe({
          next: (loans: KalpLoan[]) => {
            loans.forEach((l: KalpLoan) => {
              if (loan && l.id === loan.id) {
                l.setFromInput(loan)
              }
            })
            this.kalpService.loanInput$.next(loans)

            // In the event of scenario Conditions we take the loan amount from here
            if (this.scenario === Scenarios.Conditions) {
              const property = this.properties.find((p: Property) => p.type === BuildingTypes.keep)

              if (property) {
                const loanAmount = loans
                  .filter((l: KalpLoan) => l.property === property.id && l.solve)
                  .reduce((sum: number, l: KalpLoan) => sum + l.amount, 0)
                this.kalpService.loanAmount$.next(loanAmount)
              }

            }
            //Make a new proposal if we update other loans
            this.proposalService.makeProposal()
          }
        }
      )
  }

  public updateBuildings(): void {
    this.building$ = this.kalpService.buildings$.pipe(
    ).subscribe({
      next: (buildings: KalpBuilding[]) => {
        const keepers = [BuildingTypes.leave, BuildingTypes.keep]
        this.properties = buildings
          .filter((b: KalpBuilding) => b.type !== BuildingTypes.purchase && b.typeOfObject !== ObjectTypes.HYRESRATT)
          .map((b: KalpBuilding) => {
            const name = keepers.indexOf(b.type) !== -1 ? 'Nuvarande' : `Övrigt boende (${b.typeOfObject})`
            return {
              name,
              type: b.type,
              id: b.id
            }
          })
      }
    })
  }
}
