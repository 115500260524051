import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'
import {Subscription} from 'rxjs'
import {filter} from 'rxjs/operators'
import {KalpService, LegacyKalp} from '../../../services/kalp.service'
import {
  EmailAndPhoneComponent
} from '../email-and-phone/email-and-phone.component'

@Component({
  selector: 'spb-contact-holder',
  templateUrl: './contact-holder.component.html',
  styleUrls: [],
  imports: [EmailAndPhoneComponent]
})
export class ContactHolderComponent implements OnInit, OnDestroy {
  @Output() formStatusChanged = new EventEmitter<boolean>()

  public coApplicant = false

  private sub$ = new Subscription()

  constructor(
    private kalpService: KalpService
  ) {
  }

  public ngOnInit(): void {
    this.sub$ = this.kalpService.kalpResult$
      .pipe(
        filter<LegacyKalp[]>((kalps: LegacyKalp[]) =>
          Array.isArray(kalps) && kalps[0] !== undefined && kalps[0].applicants !== undefined)
      )
      .subscribe({
        next: (kalps: LegacyKalp[]) => {
          this.coApplicant = kalps[0].applicants.length > 1
        }
      })
  }

  /**
   * Tells the sign button if the form is valid
   * @param isValid
   */
  public onFormStatusChanged(isValid: boolean) {
    this.formStatusChanged.emit(isValid)
  }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe()
  }
}
