import {DecimalPipe} from '@angular/common'
import {Component, Input, OnChanges} from '@angular/core'
import {MatCheckbox} from '@angular/material/checkbox'
import {COLOR_LIST} from '../../../application/data-types'
import {KalpLoan} from '../../../model/kalp-loan'

@Component({
  selector: 'spb-loan-table',
  templateUrl: './loan-table.component.html',
  styleUrls: ['./loan-table.component.scss'],
  imports: [MatCheckbox, DecimalPipe]
})
export class LoanTableComponent implements OnChanges {

  @Input() loans: KalpLoan[] = []

  @Input() loanCost: [number, number, number] = [0, 0, 0]

  @Input() scenarioChange = false

  public colorList = COLOR_LIST

  public ngOnChanges() {
    // Sort by position based on 'expires' then 'terms' (must be same order in both the loans table and the timeline)
    this.loans.sort((a, b) =>
      a.expires - b.expires || a.terms - b.terms
    )
  }
}
