<div class="spb-step-mongo">
  <form [formGroup]="form" class="spb-form">
    <div formArrayName="incomes">
      <div class="section">
        @for (income of incomes.controls; let i = $index; track i) {
          <div [formGroupName]="i" class="group">
            <mat-label>{{ i === 0 ? 'Låntagare' : 'Medlåntagare' }}</mat-label>

            <mat-form-field class="margin-top-small">
              <mat-label>Inkomst före skatt</mat-label>
              <input
                [name]="'income' + i"
                formControlName="income"
                matInput
                spbFormatNumber>
              <span matTextSuffix>kr/månad</span>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Ev skattefri inkomst</mat-label>
              <input
                [name]="'taxFreeIncome' + i"
                formControlName="taxFreeIncome"
                matInput
                spbFormatNumber>
              <span matTextSuffix>kr/månad</span>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Studielån</mat-label>
              <input
                [name]="'studyLoan' + i"
                formControlName="studyLoan"
                matInput
                spbFormatNumber>
              <span matTextSuffix>kr/månad</span>
            </mat-form-field>
          </div>
        }
      </div>
    </div>

    <mat-form-field class="other-expense">
      <mat-label>Övriga kostnader</mat-label>
      <input formControlName="otherCosts"
             matInput name="otherCosts"
             spbFormatNumber>
      <span matTextSuffix>kr/månad</span>
    </mat-form-field>
    <spb-household (valid)="houseHoldValidEvent($event)"></spb-household>
  </form>
  <div class="spb-step-info">
    <p>Om kund har övriga inkomster (ex. utdelning, inkomst av kapital) som inte
      beskattas som tjänst/näring anges
      dessa efter avräkning av ev. skatt i fältet för <span
        class="spb-mongo-highlight">Ev skattefri inkomst.</span>
      Hushållets övriga kostnader (ex. barnomsorg) anges totalt i fältet för
      <span class="spb-mongo-highlight">Övriga kostnader</span>
    </p>
  </div>
</div>
