<div class="holder">
  @for (kalp of kalps; track $index) {
    <div class="chart">
      <h4 class="heading">{{ kalp.name }}</h4>
      <spb-chart [kalp]="kalp" class="chart-holder"></spb-chart>
      <div class="text">
        <div class="group">
          <div class="title">Inkomster före skatt:</div>
          <div class="value">{{ kalp.totalIncome | number: '1.0-0':'fr' }} kr
          </div>
        </div>
        <div class="group">
          <div class="title">Kostnader</div>
          <div class="value">{{ kalp.totalCost | number: '1.0-0':'fr' }} kr
          </div>
        </div>
        <div class="group">
          <div class="title">Kvar att leva på</div>
          <div class="value">{{ kalp.kalp | number: '1.0-0':'fr' }} kr</div>
        </div>
      </div>
    </div>
  }
</div>
