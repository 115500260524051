<form [formGroup]="form" class="spb-form">
  <h3>Barn</h3>
  <p>Uppgifter om barn</p>
  <mat-form-field>
    <mat-label>Antal barn</mat-label>
    <mat-select [compareWith]="childrenCompare"
                formControlName="childrenCount"
                ngDefaultControl
                placeholder="Välj i listan">
      @for (count of ['Inga', 1, 2, 3, 4, 5, 6]; track $index) {
        <mat-option [value]="count">{{ count }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  @for (child of childrenArray; let i = $index; track i) {
    <span>
      <mat-form-field>
        <mat-label>
          @if (childrenFormArray.length > 1) {
            Ålder på barn {{ i + 1 }}
          } @else {
            Ålder på barnet
          }
        </mat-label>
        <mat-select [formControl]="child"
                    ngDefaultControl
                    placeholder="Välj">
          @for (age of [0.1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]; track $index) {
            <mat-option [value]="age">{{ age | number: '1.0-0' }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </span>
  }

  <h3>Bilar</h3>
  <p>Äger hushållet bil?</p>
  <mat-form-field>
    <mat-label>Välj i listan</mat-label>
    <mat-select
      [formControl]="form.controls.haveCar"
      ngDefaultControl placeholder="Välj i listan">
      <mat-option [value]="" disabled>Välj i listan</mat-option>
      <mat-option [value]="true">Ja</mat-option>
      <mat-option [value]="false">Nej</mat-option>
    </mat-select>
    @if (form.get('carCount')?.errors) {
      <mat-error>
        Du måste välja ett alternativ.
      </mat-error>
    }
  </mat-form-field>

  @if (form.controls.haveCar.value) {
    <div>
      <p class="lead-text">Ange antal bilar</p>
      <mat-form-field>
        <mat-label>Välj i listan</mat-label>
        <mat-select [formControl]="form.controls.carCount"
                    ngDefaultControl
                    placeholder="Välj antal">
          <mat-option disabled>Välj antal</mat-option>
          @for (carCount of [1, 2, 3]; track $index) {
            <mat-option [value]="carCount">{{ carCount }}</mat-option>
          }
        </mat-select>
        @if (form.get('carCount')?.errors) {
          <mat-error>
            Ange antal bilar
          </mat-error>
        }
      </mat-form-field>
    </div>
  }

  <p>Finns det privatleasad bil i hushållet?</p>
  <mat-form-field class="standard-full-width margin-top-small">
    <mat-label>Välj i listan</mat-label>
    <mat-select [formControl]="form.controls.haveLeaseCar"
                ngDefaultControl
                placeholder="Välj i listan">
      <mat-option disabled>Välj i listan</mat-option>
      <mat-option [value]="true">Ja</mat-option>
      <mat-option [value]="false">Nej</mat-option>
    </mat-select>
    @if (form.get('privateLeaseCar')?.errors) {
      <mat-error>
        Du måste välja ett alternativ.
      </mat-error>
    }
  </mat-form-field>

  @if (form.controls.haveLeaseCar.value) {
    <div>
      <p>Ange faktiska kostnader för privatleasad bil (kronor/månad)</p>
      <mat-form-field>
        <input
          [formControl]="form.controls.leaseCarCost"
          matInput
          ngDefaultControl
          placeholder="Kostnad privatleasing"
          spbFormatNumber>
      </mat-form-field>
    </div>
  }
</form>