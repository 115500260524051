<div class="spb-step-mongo">
  <div>
    @if (scenario !== 'Omsättning/Villkorsförändring') {
      <div>
        <h3>Nytt lån</h3>
        <mat-form-field>
          <mat-label>Sökt belopp</mat-label>
          <label>
            <input [formControl]="seekedAmount"
                   matInput
                   name="price"
                   spbFormatNumber>
          </label>
        </mat-form-field>
      </div>
    }
    @for (building of buildings; let i = $index; track i) {
      <div class="building-holder">

        <spb-property (changes)="buildingChanges($event)"
                      [building]="building"
                      [seekedAmount]="seekedAmount.value"
                      [scenario]="scenario"></spb-property>

        <!-- You cannot remove the base building we are always working with one -->
        <div class="padding-bottom-small">
          @if (i !== 0) {
            <button (click)="removeBuilding(i)" color="warn" mat-stroked-button>
              <span class="material-symbols-outlined">delete</span>
            </button>
          }
        </div>
      </div>
    }

    <button
      (click)="addBuilding()"
      color="accent"
      mat-stroked-button>Lägg till övrigt boende
    </button>
  </div>
  @if (ngService.parameters$ | async; as parameters) {
    <div class="spb-step-info">
      <p>
        Ange <span class="spb-mongo-highlight">faktisk driftskostnad</span> om
        den överstiger bankens schablon - villa
        {{ parameters.propertyDefaults.VILLA.runCost }}kr,
        bostadsrätt {{ parameters.propertyDefaults.BOSTADSRATT.runCost }}
        kr, fritidshus {{ parameters.propertyDefaults.FRITIDSHUS.runCost }}kr,
        hyresrätt
        {{ parameters.propertyDefaults.HYRES.runCost }}kr
      </p>
      <p><span class="spb-mongo-highlight">Fastighetsavgift</span> beräknas per
        automatik i KALPen baserat på gällande
        regelverk, beräkning sker med högsta
        fastighetsavgift för villa och fritidshus
        (fn. {{ parameters.propertyDefaults.VILLA.yearlyTax }} kr /år)
      </p>
      @if (scenario !== scenarioTypes[0]) {
        <p>Om <span
          class="spb-mongo-highlight">Amorteringsgrundande värde</span>
          avviker mot bostadens värde ändrar du till korrekt
          amorteringsgrundande
          värde för
          att få korrekt amorteringskrav senare i rådgivningen.</p>
      }
      @if (scenario !== scenarioTypes[0]) {
        <p>Om det finns en <span
          class="spb-mongo-highlight">amorteringsgrundande skuld</span>
          på objektet som är högre än ny summa lån på bostaden ska
          amorteringsgrundande skuld anges för att
          amorteringskravet ska beräknas korrekt. Anges inget belopp i fältet
          beräknas amorteringskravet på summan av
          befintliga lån (som inte markerats för lösen) och sökt belopp.</p>
      }
    </div>
  }
</div>
