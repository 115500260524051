import {Component, Inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog'
import {ControlService} from '../../../services/control.service'
import {CustomerService} from '../../../services/customer.service'
import {StaticDataService} from '../../../services/static-data.service'

@Component({
  selector: 'spb-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class ConfirmDialogComponent {

  constructor(
    private controlService: ControlService,
    public customerService: CustomerService,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private staticDataService: StaticDataService,
    @Inject(MAT_DIALOG_DATA) public data: {
      id: string;
      message: string;
      title: string
    }
  ) {
  }

  public unLockAdvice() {
    this.controlService.locked$.next(false)
    this.staticDataService.followAdvice$.next('')
    this.dialogRef.close()
  }
}
