<h3>{{ building.heading }}</h3>
<form [formGroup]="form" class="property-form">

  <mat-form-field class="medium-width">
    <mat-label>Typ av objekt</mat-label>
    <mat-select
      (selectionChange)="setTypeOfObject()"
      formControlName="typeOfObject" ngDefaultControl>
      @for (type of objectTypes; track $index) {
        <mat-option [value]="type">
          {{ type }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
  @if (showValue) {
    <mat-form-field class="medium-width">
      <mat-label>Bostadens värde</mat-label>
      <input formControlName="objectValue"
             matInput
             name="objectValue"
             spbFormatNumber>
    </mat-form-field>
  }
  @if (showValue && scenario !== scenarioTypes[0]) {
    <mat-form-field>
      <mat-label>Amorteringsgrundande värde</mat-label>
      <label>
        <input formControlName="mortgageRequirementValue"
               matInput
               name="mortgageRequirementValue"
               spbFormatNumber>
      </label>
    </mat-form-field>
  }
  @if (showValue && scenario !== scenarioTypes[0]) {
    <mat-form-field>
      <mat-label>Amorteringsgrundande skuld</mat-label>
      <label>
        <input formControlName="mortgageRequirementDebt"
               matInput
               name="mortgageRequirementDebt"
               spbFormatNumber>
      </label>
    </mat-form-field>
  }
  @if (form.controls.typeOfObject.valid) {
    <mat-form-field class="medium-width">
      <mat-label>Driftskostnad</mat-label>
      <label>
        <input
          formControlName="runCost"
          matInput
          name="runCost"
          spbFormatNumber>
      </label>
      <span matTextSuffix>kr/mån</span>
    </mat-form-field>
  }
  @if (showFee) {
    <div>
      <mat-form-field class="medium-width">
        <mat-label>{{ rentOrFee }}</mat-label>
        <label>
          <input
            formControlName="fee"
            matInput
            name="Fee"
            spbFormatNumber
          >
        </label>
        <span matTextSuffix>kr/mån</span>
      </mat-form-field>
    </div>
  }
</form>
