import {Component, Input, OnChanges} from '@angular/core'
import {PieChartModule} from '@swimlane/ngx-charts'
import {COLOR_LIST} from '../../application/data-types'
import {KalpLoan} from '../../model/kalp-loan'

interface IChartDataHolder {
  chart: IChartValue[]
  color: any
}

interface IChartValue {
  name: string
  value: number
}

@Component({
  selector: 'spb-loan-distribution-chart',
  templateUrl: './loan-distribution-chart.component.html',
  styleUrls: [],
  imports: [PieChartModule]
})
export class LoanDistributionChartComponent implements OnChanges {

  @Input() loans: KalpLoan[] = []

  public gradient = false

  public baseColor = {
    domain: COLOR_LIST
  }

  public holder: IChartDataHolder = {chart: [], color: this.baseColor}

  public ngOnChanges(): void {
    this.createChartData(this.loans)
  }

  private createChartData(loans: KalpLoan[]): void {
    const chart: IChartValue[] = []
    loans.forEach((loan: KalpLoan, index: number) => {
      chart.push({
        name: index + 1 + '.',
        value: loan.amount
      })
    })

    this.holder = {
      chart: chart,
      color: this.baseColor
    }
  }
}
