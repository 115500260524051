import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {Subscription} from 'rxjs'
import {debounceTime, switchMap} from 'rxjs/operators'
import {
  ContactInfo,
  StaticDataService
} from '../../../services/static-data.service'

@Component({
  selector: 'spb-email-and-phone',
  templateUrl: './email-and-phone.component.html',
  styleUrls: ['./email-and-phone.component.scss'],
  imports: [ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatError]
})
export class EmailAndPhoneComponent implements OnInit, OnDestroy {
  @Input() index = 0
  @Output() formStatusChanged = new EventEmitter<boolean>()

  public form = new FormGroup({
    email: new FormControl<string>('', {
      validators: [Validators.email],
      updateOn: 'blur'
    }),
    mobile: new FormControl<string>('',
      {
        validators: [Validators.pattern(/^[\d\s+()-]{8,20}$/)],
        updateOn: 'blur'
      })
  })

  private sub$ = new Subscription()
  private contactInfo$ = new Subscription()

  constructor(private staticDataService: StaticDataService) {
  }

  public ngOnInit(): void {
    this.contactInfo$ = this.staticDataService.contactInfo$.subscribe({
      next: (value: [ContactInfo, ContactInfo]) => {
        if (value && value[this.index]) {
          this.form.patchValue(value[this.index])
        }
      }
    })

    let newVal: any
    this.sub$ = this.form.valueChanges.pipe(
      debounceTime(300),
      switchMap((val: any) => {
        newVal = val
        return this.staticDataService.contactInfo$
      })
    ).subscribe({
      next: (contactInfo: ContactInfo[]) => {
        // Tells the sign button if the form is valid
        this.formStatusChanged.emit(this.form.valid)

        // Hmm is this smart or crazy? We directly assign data to the object
        // instead of modifying it and return a new one
        if (contactInfo && contactInfo[this.index]) {
          Object.assign(contactInfo[this.index], newVal)
        }
      }
    })
  }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe()
    this.contactInfo$.unsubscribe()
    this.staticDataService.contactInfo$.next([{
      email: '',
      mobile: ''
    }, {email: '', mobile: ''}])
  }
}
