import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatOption} from '@angular/material/core'
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {Subscription} from 'rxjs'
import {BuildingTypes, KalpBuilding} from '../../../model/kalp-building'
import {
  KalpService,
  ObjectTypes,
  Scenarios
} from '../../../services/kalp.service'

@Component({
  selector: 'spb-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss'],
  imports: [ReactiveFormsModule, MatFormField, MatLabel, MatSelect, MatOption, MatInput, MatSuffix, FormatNumberDirective]
})
export class PropertyComponent implements OnInit, OnDestroy {

  /**
   * The parent tells us which index we are
   */
  @Input() building: KalpBuilding = new KalpBuilding()

  /**
   * The parent tells us which scenario we are running.
   */
  @Input() scenario: Scenarios = Scenarios.Purchase

  /**
   *
   */
  @Input() seekedAmount = 0

  /**
   * Any changes go here.
   */
  @Output() changes = new EventEmitter<KalpBuilding>()

  /**
   * Simple export to the template.
   */
  public scenarioTypes = [Scenarios.Purchase, Scenarios.Increase, Scenarios.Conditions, Scenarios.Transfer]

  /**
   * Simple export to the template.
   */
  public objectTypes = [ObjectTypes.VILLA, ObjectTypes.BOSTADSRATT, ObjectTypes.FRITIDSHUS]

  /**
   * This is our form.
   */
  public form = new FormGroup<any>({
    id: new FormControl(),
    typeOfObject: new FormControl(undefined, Validators.required),
    objectValue: new FormControl('', [Validators.required]),
    mortgageRequirementValue: new FormControl(''),
    mortgageRequirementDebt: new FormControl(''),
    fee: new FormControl(undefined, Validators.required),
    runCost: new FormControl(undefined, Validators.required)
  })

  private form$ = new Subscription()

  private propertyValue$ = new Subscription()

  constructor(private kalpService: KalpService) {
  }

  get objectType(): ObjectTypes {
    return this.form.controls.typeOfObject.value
  }

  get showFee(): boolean {
    const feeObjects = [ObjectTypes.BOSTADSRATT, ObjectTypes.HYRESRATT]
    return feeObjects.indexOf(this.objectType) !== -1
  }

  get showValue(): boolean {
    const feeObjects = [ObjectTypes.BOSTADSRATT, ObjectTypes.VILLA, ObjectTypes.FRITIDSHUS]
    const propOb = [BuildingTypes.purchase, BuildingTypes.keep]
    return feeObjects.indexOf(this.objectType) !== -1 && propOb.indexOf(this.building.type) !== -1
  }

  get rentOrFee(): string {
    return this.objectType === ObjectTypes.HYRESRATT ? 'Hyra' : 'Avgift'
  }

  public ngOnInit(): void {
    this.form.patchValue(this.building)
    this.form.controls.runCost.setValue(this.building.runCost)
    this.form.controls.mortgageRequirementValue.setValue(this.building.mortgageRequirementValue)
    this.form.controls.mortgageRequirementDebt.setValue(this.building.mortgageRequirementDebt)

    const types = [BuildingTypes.leave, BuildingTypes.other]
    if (types.indexOf(this.building.type) !== -1) {
      this.objectTypes.push(ObjectTypes.HYRESRATT)
      this.form.controls.objectValue.clearValidators()
      this.form.updateValueAndValidity()
    }

    this.form$ = this.form.valueChanges
      .pipe()
      .subscribe({
        next: (val: KalpBuilding) => {
          this.building.valid = this.form.valid
          this.changes.emit(Object.assign(this.building, val))

          /**
           * send the seeked loan amount to kalp service if "bostadens värde is updated"
           */
          this.kalpService.loanAmount$.next(+this.seekedAmount)
        }
      })

    this.propertyValue$ = this.form.controls.objectValue.valueChanges.subscribe({
      next: (val: number) => {
        if (!this.form.controls.mortgageRequirementValue.touched) {
          this.form.controls.mortgageRequirementValue.setValue(val)
        }
      }
    })
  }

  public ngOnDestroy(): void {
    this.form$.unsubscribe()
    this.propertyValue$.unsubscribe()
  }

  /**
   * This is run every time the type of Object changes.
   */
  public setTypeOfObject(): void {
    // We take the type of object from the control and update our "building"
    // with that value
    // Set the run cost to default
    this.building.runCost = -1
    this.form.controls.runCost.setValue(this.building.runCost)
    if (!this.showFee) {
      this.form.controls.fee.setValue(0)
    } else {
      this.form.controls.fee.setValue(this.building.fee)
    }


    if (!this.showValue) {
      // Do not understand why this needs a timeout.
      setTimeout(() => this.form.controls.objectValue.setValue(''), 1)
    }

    // This we do to set validity of the object even if no value has changed.
    setTimeout(() => this.form.controls.runCost.setValue(this.building.runCost), 1)
  }
}
