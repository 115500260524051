<mat-accordion multi>
  <mat-expansion-panel expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title class="fixed-title-width">
        Inkomster
      </mat-panel-title>
      <mat-panel-description class="fixed-title-width">
        {{ kalpResult.totalIncome | number:'1.0-0':'fr' }} kr/månad
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <table class="mat-elevation-z5">
        <caption>Inkomster</caption>
        <tr>
          <th scope="col">Låntagare</th>
          <th scope="col">Inkomst</th>
          <th scope="col">Skattefria inkomster</th>
          <th scope="col">Barnbidrag</th>
        </tr>
        @for (applicant of kalp.applicants; let i = $index; track i) {
          <tr>
            <td>{{ applicant.name }} @if (applicant.reducedIncome) {
              <span>*</span>
            }</td>
            <td class="value">{{ kalp.incomes[i].income | number:'1.0-0':'fr' }}
              kr/månad
            </td>
            <td
              class="value">{{ kalp.incomes[i].taxFreeIncome | number:'1.0-0':'fr' }}
              kr/månad
            </td>
            @if (i === 0) {
              <td class="value">{{ kalp.children.income | number:'1.0-0':'fr' }}
                kr/månad
              </td>
            }
            @if (i === 1) {
              <td></td>
            }
          </tr>
        }
        <tr>
          <td><span class="bold">Totalt för hushållet</span></td>
          <td class="value">{{ kalp.income.income | number:'1.0-0':'fr' }}
            kr/månad
          </td>
          <td
            class="value">{{ kalp.income.taxFreeIncome | number:'1.0-0':'fr' }}
            kr/månad
          </td>
          <td class="value">{{ kalp.children.income | number:'1.0-0':'fr' }}
            kr/månad
          </td>
        </tr>
      </table>
    </ng-template>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title class="fixed-title-width">
        Kostnader
      </mat-panel-title>
      <mat-panel-description class="fixed-title-width">
        {{ kalpResult.directCosts | number:'1.0-0':'fr' }} kr/månad
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <table class="mat-elevation-z5">
        <caption>Costs</caption>
        <tr>
          <th id="0">Låntagare</th>
          <th id="1">Skatt</th>
          <th id="2">Studielån</th>
          <th id="3">Övriga kostnader</th>
          <th id="4">Bilar</th>
        </tr>
        @for (applicant of kalp.applicants; let i = $index; track i) {
          <tr>
            <td>{{ applicant.name }} @if (applicant.reducedIncome) {
              <span>*</span>
            }</td>
            <td class="value">{{ kalp.incomes[i].tax | number:'1.0-0':'fr' }}
              kr/månad
            </td>
            <td
              class="value">{{ kalp.incomes[i].studyLoan | number:'1.0-0':'fr' }}
              kr/månad
            </td>
            @if (i === 0) {
              <td
                class="value">{{ kalp.income.otherCosts | number:'1.0-0':'fr' }}
                kr/månad
              </td>
              <td class="value">{{ kalp.costOfCars | number:'1.0-0':'fr' }}
                kr/månad
              </td>
            }
            @if (i === 1) {
              <td class="value"></td>
            }
          </tr>
        }
        <tr>
          <td><span class="bold">Totalt för hushållet</span></td>
          <td class="value">{{ kalp.income.tax | number:'1.0-0':'fr' }}
            kr/månad
          </td>
          <td class="value">{{ kalp.income.studyLoan | number:'1.0-0':'fr' }}
            kr/månad
          </td>
          <td class="value">{{ kalp.income.otherCosts | number:'1.0-0':'fr' }}
            kr/månad
          </td>
          <td class="value">{{ kalp.costOfCars | number:'1.0-0':'fr' }}
            kr/månad
          </td>
        </tr>
      </table>
    </ng-template>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title class="fixed-title-width">
        Boende
      </mat-panel-title>
      <mat-panel-description class="fixed-title-width">
        {{ monthlyBuildingCost | number:'1.0-0':'fr' }} kr/månad
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <table class="table mat-elevation-z5">
        <caption>Boenden</caption>
        <tr>
          <th id="hedaing">Boende</th>
          <th id="runCost">Driftskostnad</th>
          <th id="tax">Fastighetsavgift</th>
          <th id="fee">Avgift/Hyra</th>
          <th id="sum">Summa</th>
        </tr>
        @for (building of getBuildings(); track $index) {
          <tr>
            <td>{{ building.heading }} ({{ building.typeOfObject }})</td>
            <td>{{ building.runCost | number:'1.0-0':'fr' }} kr / månad</td>
            <td>{{ building.propertyTax | number:'1.0-0':'fr' }} kr / månad</td>
            <td>{{ building.fee | number:'1.0-0':'fr' }} kr / månad</td>
            <td>{{ building.monthlyCost | number:'1.0-0':'fr' }} kr / månad</td>
          </tr>
        }
        <tr>
          <td class="bold">Totalt för boende</td>
          <td>{{ runCost | number:'1.0-0':'fr' }} kr / månad</td>
          <td>{{ propertyTax | number:'1.0-0':'fr' }} kr / månad</td>
          <td>{{ fee| number:'1.0-0':'fr' }} kr / månad</td>
          <td>{{ propertySum| number:'1.0-0':'fr' }} kr / månad</td>
        </tr>
      </table>
    </ng-template>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title class="fixed-title-width">
        Lån
      </mat-panel-title>
      <mat-panel-description class="fixed-title-width">
        {{ getMonthlyLoanCost() - kalp.loans.taxReturn | number:'1.0-0':'fr' }}
        kr / månad
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <table class="table mat-elevation-z5">
        <caption>List of loans</caption>
        <tr class="row">
          <th class="table-heading" scope="col">Belopp</th>
          <th class="table-heading" scope="col">Amortering</th>
          <th class="table-heading" scope="col">Ränta</th>
          <th class="table-heading" scope="col">Månadskostnad</th>
          @if (showCol) {
            <th class="table-heading center" scope="col">Löses</th>
            <th class="table-heading center" scope="col">Nytt</th>
          } @else {
            <th class="table-heading center" scope="col">
              Omsättning/Villkorsändring
            </th>
          }
        </tr>
        @for (loan of getLoans(); track $index) {
          <tr>
            <td
              class="table-value number">{{ loan.amount | number:'1.0-0':'fr' }}
              kr {{ loan.isBlancoLoan ? '*' : '' }}
            </td>
            <td
              class="table-value number">{{ loan.mortgageAmount| number:'1.0-0':'fr' }}
              kr
            </td>
            <td
              class="table-value number">{{ loan.interestAmount | number:'1.0-0':'fr' }}
              kr
            </td>
            <td class="table-value number">{{ loan.cost | number:'1.0-0':'fr' }}
              kr
            </td>
            @if (showCol) {
              <td class="table-value center">
                @if (loan.solve && !kalp.current) {
                  <mat-icon color="accent">check</mat-icon>
                }
              </td>
              <td class="table-value center">
                @if (loan.new) {
                  <mat-icon color="accent">check</mat-icon>
                }
              </td>
            } @else {
              <td class="table-value center">
                @if (loan.new || loan.solve) {
                  <mat-icon color="accent">check</mat-icon>
                }
              </td>
            }
          </tr>
        }
        <tr>
          <td class="bold">Totalt för lån</td>
          <td class="number">{{ getMortgageSum() | number:'1.0-0':'fr' }} kr
          </td>
          <td class="number">{{ getInterestSum() | number:'1.0-0':'fr' }} kr
          </td>
          <td class="number">{{ getMonthlyLoanCost() | number:'1.0-0':'fr' }}
            kr
          </td>
        </tr>
      </table>
      <p></p>
      <p>* Befintliga privatlån, konto- & kortkrediter</p>
      <div class="group">
        <div class="item">
          <div class="label">Skattereduktion</div>
          <div
            class="value">{{ kalp.loans.taxReturn * -1 | number:'1.0-0':'fr' }}
            kr/månad
          </div>
        </div>
      </div>
    </ng-template>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title class="fixed-title-width">
        KALP överskott/underskott
      </mat-panel-title>
      <mat-panel-description class="fixed-title-width">
        {{ kalpResult.kalp | number:'1.0-0':'fr' }} kr / månad
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <table class="mat-elevation-z5">
        <caption>Sammantsällning och budget</caption>
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
        <tr>
          <td>Kvar att leva på efter boendekostnad</td>
          <div class="border-spacing-0">
            <td>{{ kalpResult.totalIncome - kalpResult.totalCost + kalpResult.livingExpense | number:'1.0-0':'fr' }}
              kr / månad
            </td>
          </div>
        </tr>
        <tr>
          <td>Budget/levnadskostnader</td>
          <div class="border-spacing-0">
            <td>{{ (kalpResult.livingExpense)| number:'1.0-0':'fr' }} kr
              /månad
            </td>
          </div>
        </tr>
        <tr>
          <td>Överskott/Underskott</td>
          <div class="border-spacing-0">
            <td class="sum">{{ kalpResult.kalp | number:'1.0-0':'fr' }} kr /
              månad
            </td>
          </div>
        </tr>
      </table>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
