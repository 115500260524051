<table>
  <caption style="display: none;">Foo</caption>
  <tr>
    <th scope="col">Belopp</th>
    <th scope="col">Amortering</th>
    <th scope="col">Ränta</th>
    <th scope="col">Räntebindning</th>
    <th scope="col">Villkors-<br>ändringsdag</th>
    @if (conditions) {
      <th scope="col">Omsättning/<br>Villkorsändring</th>
    } @else {
      <th scope="col">Löses</th>
    }
  </tr>
  @for (loan of loans; track $index) {
    <tr class="row">
      <td>{{ loan.amount }} kr</td>
      <td>{{ loan.mortgage }} kr/mån</td>
      <td>{{ loan.interest }} %</td>
      <td>{{ loan.terms }}</td>
      <td>{{ loan.expires }}</td>
      <td class="center">
        <mat-checkbox [checked]="loan.solve" disabled></mat-checkbox>
      </td>
    </tr>
  }
</table>
<p class="guide">För att ändra befintliga lån gå till flik
  <span (click)="controlService.step$.next(3)"
        (keyup.enter)="controlService.step$.next(3)"
        class="link"
        tabindex="-1">Befintliga lån</span>
</p>