import {
  Applicant,
  Car,
  Child,
  Income,
  Kalpylator,
  Loan,
  Parameters,
  Property
} from '@sparbanken-syd/kalpylator'
import {
  getEmptyLegacyKalp,
  KalpApplicant,
  KalpIncome,
  KalpInput,
  LegacyKalp,
  TTypeOfKalp
} from '../services/kalp.service'
import {NGService} from '../services/ng.service'
import {KalpBuilding} from './kalp-building'
import {KalpLoan} from './kalp-loan'
import {KalpylatorHelper} from './kalpylator-helper'


export class KalpylatorWrapper {

  private input: KalpInput

  private parameters: Parameters = {} as any

  private extraInterest: number

  private name: TTypeOfKalp

  constructor(private ngService: NGService, kalpInput: KalpInput, extraInterest = 0, name: TTypeOfKalp = 'new') {
    this.input = kalpInput
    this.name = name
    this.extraInterest = extraInterest
    ngService.parameters$.subscribe({
      next: (p: Parameters) => this.parameters = p
    })
  }

  public calculate(): LegacyKalp {
    const ng = new Kalpylator(this.parameters)

    ng.set<Child>(this.ngService.ngChildren$.value)
    ng.set<Car>(this.ngService.ngCars$.value)
    ng.set<Applicant>(this.ngService.ngApplicants$.value)
    ng.set<Income>(this.ngService.ngIncomes$.value)

    const otherMortgageLoans = this.input.otherMortgageLoans
      .map((l: KalpLoan) => KalpylatorHelper.convertKalpLoanToNG(l))

    const privateLoans = this.input.privateLoans.map((l: KalpLoan) => KalpylatorHelper.convertKalpLoanToNG(l))

    ng.set<Loan>(otherMortgageLoans.concat(privateLoans))

    ng.set<Property>(this.input.buildings.map((b: KalpBuilding) => KalpylatorHelper.convertBuildingToNG(b)))

    // New Kalpylator wants points old has 0.02, note this runs the calculus
    ng.setIncreasedInterest(this.extraInterest * 100 * 100)

    this.ngService.setKalp(ng, this.name)
    return this.convertNGToLegacy(ng)
  }

  private convertNGToLegacy(kalp: Kalpylator): LegacyKalp {
    const legacy = getEmptyLegacyKalp()
    legacy.applicants = kalp.applicants.map((applicant: Applicant) =>
      KalpylatorHelper.convertApplicantToLegacy<KalpApplicant>(applicant))

    legacy.incomes = kalp.incomes.map((income: Income) =>
      KalpylatorHelper.convertIncomeToLegacyResult(income as Required<Income>))
    this.setLegacyIncome(legacy)

    legacy.buildings = kalp.properties.map((property: Property) => {
      const b = new KalpBuilding()
      b.setBuildingFromKalpInput(property)
      return b
    })

    // This is odd. Bc in Kalpylator we do not increase the actual loan with the
    // increased interest
    legacy.loans.loans = kalp.loans.map((l: Loan) =>
      KalpylatorHelper.convertNGLoanToLegacyResult(l as Required<Loan>))

    legacy.loans.taxReturn = kalp.monthlyLoanTaxReturn
    legacy.loans.cost = kalp.monthlyLoanCost

    // Set all top level values
    this.setKalpValues(legacy, kalp)

    return legacy
  }

  /**
   * Detect and transfer all top level values in the legacy kalp
   */
  private setKalpValues(legacy: LegacyKalp, kalp: Kalpylator): void {
    /**
     * For legacy reasons, we transfer all sorts of values
     */
    // Start with cars.
    legacy.carCount = kalp.ownedCarCount
    legacy.haveCar = legacy.carCount > 0
    legacy.haveLeaseCar = kalp.leaseCarCost > 0
    legacy.leaseCarCost = kalp.leaseCarCost

    // Then the children, quite simple.
    legacy.children = {
      childrenBenefit: kalp.childrenBenefitTotal,
      childrenBenefitExtra: kalp.childrenBenefitExtra,
      cost: kalp.monthlyChildrenCost,
      count: kalp.children.length,
      income: kalp.monthlyChildrenIncome
    }
    // Silly assertion since the age is set by the kalpylator (to 17), always
    legacy.childrenAges = kalp.children.map((c: Child): number => c.age as number)

    // A bunch of summary values
    legacy.livingExpense = kalp.monthlyLivingExpense
    legacy.kalp = kalp.kalp
    legacy.purchaseAmount = kalp.newLoanTotalAmount
    legacy.costOfBuildings = kalp.monthlyPropertyCost

    legacy.totalCost = kalp.cost
    legacy.totalIncome = kalp.income
    legacy.reducedIncome = this.setReducedIncome(kalp.applicants)
    legacy.costOfCars = kalp.monthlyCarCost

    // The very tricky debtQuota

    legacy.debtQuota = {
      amortizationRequirement: kalp.amortizationRequirement,
      income: kalp.annualTotalIncome,
      loans: kalp.totalMortgageLoanAmount,
      quota: kalp.debtQuota as number * 100
    }
  }

  /**
   * TODO: Refactor this stuff!
   * @param applicants
   * @private
   */
  private setReducedIncome(applicants: Applicant[]): string {
    let res = ''
    const reducedIncome: boolean = applicants.some(a => a.reducedIncome === true)
    if (reducedIncome) {
      const reducers = applicants
        .filter((applicant: Applicant) => applicant.reducedIncome)
        .map((applicant: Applicant) => applicant.name)
      res = `Inkomst är nedskriven med 30% på ${reducers.join(' och ')}, för att ta ` +
        `hänsyn till framtida inkomstförändringar som tex pension.`
    }
    return res
  }

  /**
   * Aggregates the incomes to the top level object.
   * Most of these properties are not needed and should
   * be removed eventually
   * @private
   */
  private setLegacyIncome(legacy: LegacyKalp): void {
    const i: KalpIncome = {
      column: 0,
      income: 0,
      net: 0,
      otherCosts: 0,
      studyLoan: 0,
      tax: 0,
      taxFreeIncome: 0
    }

    Object.keys(i).forEach((k: string) => {
      (i as any)[k] = this.aggregateProperty(legacy.incomes, k)
    })
    legacy.income = i
  }

  private aggregateProperty(o: any[], k: string): number {
    return o.map((o: any) => o[k]).reduce((acc: number, cur: number) => acc + cur, 0)
  }
}
