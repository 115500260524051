import {Component, Input, OnInit} from '@angular/core'
import {AbstractControl, FormControl} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {Router} from '@angular/router'
import {filter} from 'rxjs/operators'
import {HOME_ROUTE_PATH} from '../../../application/data-types'
import {CustomerService} from '../../../services/customer.service'
import {DatastoreService} from '../../../services/datastore.service'

@Component({
  selector: 'spb-duplicate',
  templateUrl: './duplicate.component.html',
  styleUrls: ['./duplicate.component.scss'],
  imports: [MatButton]
})
export class DuplicateComponent implements OnInit {

  @Input() group: AbstractControl = new FormControl<string>('', {nonNullable: true})

  @Input() applicantNumber = 0

  private loaded = ''

  constructor(
    public customerService: CustomerService,
    private dataStoreService: DatastoreService,
    public router: Router
  ) {
  }

  get personNummer(): string {
    return this.group.value
  }

  public ngOnInit(): void {
    this.group.statusChanges
      .pipe(
        // Very important to check against the VALUE not the group status as that may change
        // when someone updates the group.
        filter((v: string) => v === 'VALID')
      )
      .subscribe({
        next: () => {
          if (this.loaded !== this.personNummer && !this.customerService.existingCustomers.has(this.personNummer)) {
            this.loaded = this.personNummer
            this.dataStoreService.loadKalp(this.personNummer, this.applicantNumber)
          }
        }
      })
  }

  /**
   * Load an existing customer when it already exists.
   *
   * Only call this when you have verified that the customer DO exist
   */
  public loadCustomer(): void {
    const id = this.customerService.existingCustomers.get(this.personNummer)
    this.router.navigate([HOME_ROUTE_PATH, id]).then()
  }
}
