import {AsyncPipe, DecimalPipe, NgClass} from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule
} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatOption} from '@angular/material/core'
import {
  MatExpansionPanel,
  MatExpansionPanelContent,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion'
import {MatFormField, MatSuffix} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {Subscription} from 'rxjs'
import {debounceTime, filter, switchMap} from 'rxjs/operators'
import {KalpBuilding} from '../../model/kalp-building'
import {KalpLoan} from '../../model/kalp-loan'
import {TermsTexts, TTermDuration} from '../../model/terms-texts'
import {ControlService} from '../../services/control.service'
import {KalpService, Scenarios} from '../../services/kalp.service'
import {Proposal, ProposalService} from '../../services/proposal.service'
import {AdviceComponent} from '../advice/advice.component'
import {CalculatorComponent} from '../calculator/calculator.component'
import {
  ExistingDisplayComponent
} from '../existing-display/existing-display.component'
import {FollowAdviceComponent} from '../follow-advice/follow-advice.component'
import {MiniKalpComponent} from '../mini-kalp/mini-kalp.component'
import {CommentsComponent} from './comments/comments.component'

@Component({
  selector: 'spb-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CalculatorComponent, MiniKalpComponent, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelContent, ExistingDisplayComponent, MatExpansionPanelDescription, NgClass, MatButton, ReactiveFormsModule, MatFormField, MatInput, MatSuffix, MatSelect, MatOption, AdviceComponent, FollowAdviceComponent, CommentsComponent, AsyncPipe, DecimalPipe, FormatNumberDirective]
})
export class ProposalComponent implements OnInit, OnDestroy {

  @Output() stepValid: EventEmitter<boolean> = new EventEmitter<boolean>()

  public form = new FormGroup({
    loans: new FormArray([])
  })

  public loanAmount = 0

  public showRemaining = true

  public showExisting = false

  /**
   * Set this to true if the proposal is man made
   */
  public manualProposal = false

  /**
   * Used in help texts for display purposes.
   */
  public scenarioChange = false

  /**
   * Export to template
   */
  public termsTexts = TermsTexts.getValueArray()

  /**
   * If advice is selected
   */
  public adviceSelected = false

  /**
   * We do listen to proposal changes from the service
   * this is just so that we can unsubscribe
   */
  private proposal$ = new Subscription()

  constructor(
    public controlService: ControlService,
    public proposalService: ProposalService,
    private kalpService: KalpService
  ) {
  }

  get leftToDistribute(): number {
    const t: FormArray = this.form.get('loans') as FormArray
    const loans: KalpLoan[] = t.getRawValue() as KalpLoan[]
    const loanSum = loans.reduce((sum: number, loan: KalpLoan) => sum + +loan.amount, 0)

    /**
     * Only show the next button if the advice is selected and no changes in loan proposals
     */
    this.stepValid.emit(((this.loanAmount - loanSum) === 0) && this.adviceSelected)
    return this.loanAmount - loanSum
  }

  get proposals(): FormArray {
    return this.form.get('loans') as FormArray
  }

  private static createForm(proposedLoan: KalpLoan): FormGroup {

    const valueChange = (formControl: AbstractControl) => (val: TTermDuration) =>
      formControl.setValue((TermsTexts.getByMonths(val).interestPercent * 100).toFixed(2))


    const fg = new FormGroup({
      id: new FormControl(proposedLoan.id),
      amount: new FormControl(proposedLoan.amount),
      interestPercent: new FormControl(
        (proposedLoan.interestPercent * 100).toFixed(2)),
      property: new FormControl(proposedLoan.property),
      mortgagePercent: new FormControl(proposedLoan.mortgagePercent * 100),
      mortgageAmount: new FormControl(proposedLoan.mortgageAmount),
      // TODO: Make sure Loan only has TTermDurations
      terms: new FormControl<TTermDuration>(proposedLoan.terms as TTermDuration, {nonNullable: true}),
      new: new FormControl(proposedLoan.new),
      solve: new FormControl(proposedLoan.solve)
    })

    fg.controls.terms.valueChanges.pipe(
    ).subscribe({
      next: valueChange(fg.controls.interestPercent)
    })
    return fg
  }

  /**
   * When we change something we emit new loans to KALP
   * so that KALP is updated accordingly.
   */
  public ngOnInit(): void {

    this.form.valueChanges.pipe(
      filter(() => this.leftToDistribute === 0
      ),
      debounceTime(800)
    ).subscribe(() => {
      this.update()
    })


    this.proposal$ = this.kalpService.scenarioChange$.pipe(
      filter((scenario: Scenarios | null) => scenario !== null),
      switchMap((scenario: Scenarios | null) => {
        this.showRemaining = scenario !== Scenarios.Conditions
        this.showExisting =
          [Scenarios.Increase, Scenarios.Conditions, Scenarios.Transfer].indexOf(scenario as Scenarios) !== -1
        this.scenarioChange = scenario === Scenarios.Conditions
        return this.kalpService.loanAmount$
      }),
      switchMap((amount: number) => {
        this.loanAmount = amount
        return this.proposalService.proposal$
      }),
      filter((proposal: Proposal) => {
        this.resetProposals()
        return !!proposal
      })
    )
      .subscribe({
        next: (proposal: Proposal) => {
          proposal.loans.forEach((proposedLoan: KalpLoan) => {
            const prop = ProposalComponent.createForm(proposedLoan)
            this.proposals.push(prop, {emitEvent: false})
          })
          this.manualProposal = proposal.manual
        }
      })
  }

  public ngOnDestroy(): void {
    this.proposal$.unsubscribe()
  }

  public removeLoan(i: number): void {
    this.manualProposal = true
    this.proposals.removeAt(i)
  }

  public addOtherLoan(): void {
    this.manualProposal = true
    const loan = new KalpLoan(this.leftToDistribute)
    const loanForm = ProposalComponent.createForm(loan)
    this.proposals.push(loanForm)
  }

  public setValid(valid: boolean): void {
    this.adviceSelected = valid
    this.stepValid.emit(valid)
  }

  /**
   * Only called by _MANUAL_ value changes in the form.
   */
  private update(): void {
    const val = this.form.getRawValue()
    this.kalpService.getActiveBuilding().subscribe({
      next: (building: KalpBuilding) => {
        const res = val.loans.map((loan: KalpLoan): KalpLoan => {
          this.kalpService.makeNumeric(loan)
          loan.interestPercent = loan.interestPercent / 100
          const newLoan = new KalpLoan(loan.amount)
          newLoan.setFromInput(loan)
          newLoan.property = building.id
          newLoan.new = true
          return newLoan
        })
        const proposal: Proposal = {
          loanAmount: res.reduce((total: number, loan: KalpLoan) => total + loan.amount, 0),
          loans: res,
          manual: true
        }
        this.kalpService.setOtherLoans(proposal)
        this.proposalService.proposal$.next(proposal)
        this.manualProposal = true
      }
    })
  }

  private resetProposals(): void {
    while (this.proposals.length != 0) {
      this.proposals.removeAt(0, {emitEvent: false})
    }
  }
}
