import {DecimalPipe} from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core'
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelContent,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion'
import {MatIcon} from '@angular/material/icon'
import {Subscription} from 'rxjs'
import {filter} from 'rxjs/operators'
import {KalpBuilding} from '../../model/kalp-building'
import {KalpLoan} from '../../model/kalp-loan'
import {KalpResult} from '../../model/kalp-result'
import {
  getEmptyLegacyKalp,
  KalpService,
  LegacyKalp,
  Scenarios,
  TLegacyKalps
} from '../../services/kalp.service'

@Component({
  selector: 'spb-kalp-display',
  templateUrl: './kalp-display.component.html',
  styleUrls: ['./kalp-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, MatExpansionPanelContent, MatIcon, DecimalPipe]
})

export class KalpDisplayComponent implements OnInit, OnDestroy {

  @Input() kalp: LegacyKalp = getEmptyLegacyKalp()

  /**
   * Which of the three KALPS?
   */
  @Input() index = 0

  public scenario: Scenarios = Scenarios.Purchase

  public kalpResult: KalpResult = new KalpResult()

  private allLoans: KalpLoan[] = []

  private loan$ = new Subscription()

  private scenario$ = new Subscription()

  private kalp$ = new Subscription()

  constructor(
    public kalpService: KalpService
  ) {
  }

  get monthlyBuildingCost(): number {
    return this.kalp.buildings.reduce((sum: number, building: KalpBuilding) => sum + building.monthlyCost, 0)
  }

  get runCost(): number {
    return this.kalp.buildings
      .reduce((acc: number, b: KalpBuilding) => acc + b.runCost, 0)
  }

  get propertyTax(): number {
    return this.kalp.buildings
      .reduce((acc: number, b: KalpBuilding) => acc + b.propertyTax, 0)
  }

  get fee(): number {
    return this.kalp.buildings
      .reduce((acc: number, b: KalpBuilding) => acc + b.fee, 0)
  }

  get propertySum(): number {
    return this.kalp.buildings
      .reduce((acc: number, b: KalpBuilding) => acc + b.monthlyCost, 0)
  }

  /**
   * Used to tell if the new / solve columns should be shown
   */
  get showCol(): boolean {
    return this.scenario !== Scenarios.Conditions
  }

  public ngOnInit(): void {
    this.scenario$ = this.kalpService.scenarioChange$
      .pipe(
        filter((scenario: Scenarios | null) => !!scenario))
      .subscribe({
        next: (scenario: Scenarios | null) => this.scenario = scenario as Scenarios
      })

    this.kalp$ = this.kalpService.kalpResult$
      .pipe()
      .subscribe({
        next: ((kalps: TLegacyKalps) => {
          this.kalpResult = new KalpResult(kalps[this.index])
        })
      })

    this.loan$ = this.kalpService.loans$.subscribe({
      next: (loans: KalpLoan[]) => this.allLoans = loans
    })
  }

  public ngOnDestroy(): void {
    this.scenario$.unsubscribe()
    this.kalp$.unsubscribe()
  }

  public getBuildings(): KalpBuilding[] {
    return this.kalp.buildings
  }

  public getMortgageSum(): number {
    return this.kalp.loans.loans
      .filter((loan: KalpLoan) => !loan.solve || this.index === 0)
      .reduce((acc, val) => acc + val.mortgageAmount, 0)
  }

  public getInterestSum(): number {
    return this.kalp.loans.loans
      .filter((loan: KalpLoan) => !loan.solve || this.index === 0)
      .reduce((acc, val) => acc + val.interestAmount, 0)
  }

  public getMonthlyLoanCost(): number {
    return this.getInterestSum() + this.getMortgageSum()
  }

  /**
   * Only used for display, we display all loans
   * even if they are not in the KALP, strange.
   */
  public getLoans(): KalpLoan[] {
    const loans = this.allLoans
      .filter((loan: KalpLoan) => {
        /**
         * If scenario Conditions we return ??
         */
        if (this.scenario === Scenarios.Conditions) {
          if (this.index !== 0 && loan.solve) {
            return false
          }
        }
        // Skip "Before change" KALP we remove all new loans
        return !(this.index === 0 && loan.new)
      })
    return this.arrangeLoans(loans)
  }

  public arrangeLoans(loans: KalpLoan[]): KalpLoan[] {
    const blanco = this.sortSortLoans(loans.filter((loan: KalpLoan) => loan.isBlancoLoan))
    const keepers = this.sortSortLoans(loans.filter((loan: KalpLoan) => !loan.solve && !loan.new && !loan.isBlancoLoan))
    const solvers = this.sortSortLoans(loans.filter((loan: KalpLoan) => loan.solve && !loan.isBlancoLoan))
    const newLoans = this.sortSortLoans(loans.filter((loan: KalpLoan) => loan.new))
    return blanco.concat(keepers, solvers, newLoans)
  }

  public sortSortLoans(loans: KalpLoan[]): KalpLoan[] {
    return loans.sort((a: KalpLoan, b: KalpLoan): number => {
      return b.amount - a.amount
    })
  }
}
