import {Injectable} from '@angular/core'
import {Spouse} from '@sparbanken-syd/kalpylator'
import {BehaviorSubject, Observable} from 'rxjs'
import {debounceTime, filter, first, map, switchMap} from 'rxjs/operators'
import {Insurances} from '../main/8-summary/summary.component'
import {Advice} from '../model/advice'
import {KalpBuilding} from '../model/kalp-building'
import {KalpLoan} from '../model/kalp-loan'
import {ConfigService} from './config.service'
import {ControlService} from './control.service'
import {Customer, CustomerService} from './customer.service'
import {
  KalpApplicant,
  KalpIncome,
  KalpService,
  LegacyKalp,
  Scenarios
} from './kalp.service'
import {Proposal, ProposalService, Questions} from './proposal.service'
import {ContactInfo, StaticDataService} from './static-data.service'

/**
 * How we store data in the backend from the first step
 *
 */
export interface SaveDataApplicantHolder {
  applicants: SaveDataApplicant[]

  /**
   * Should, could, be derived from chledren length, but
   * old save data does not have this property
   */
  childrenCount: number | null

  /**
   * Currently a list of ages 0..18 years old.
   */
  children: number[]
  coApplicant: boolean
  declines: boolean
  applicantSpouses: boolean
  haveCar: boolean | null
  carCount: number
  haveLeaseCar: boolean | null
  leaseCarCost?: number

}

export interface SaveDataApplicant {
  name: string
  personNummer: string
  retired?: boolean
  reducedIncome?: boolean
  email?: string
  mobile?: string

  hasSpouse?: boolean
  spouse?: Spouse
  akassa?: boolean
  employer?: string
}

export interface SaveDataIncome {
  income: number | null
  taxFreeIncome: number
  studyLoan: number
}

export interface SaveDataIncomeHolder {
  incomes: SaveDataIncome[]
  otherCosts?: number
}

export interface SaveDataLivingHolder {
  seekedAmount: number | undefined
  buildings: KalpBuilding[]
}

export interface SaveDataLoan {
  amount: number
  solve: boolean
  mortgageAmount: number
  interestPercent: number
  isBlancoLoan: boolean
  property?: string
  expires: number
  pTerms: number
}

export interface SaveDataLoanHolder {
  privateLoans: SaveDataLoan[]
  otherMortgageLoans: SaveDataLoan[]
}

export interface SaveData {
  version?: 1
  scenario: Scenarios
  applicants: SaveDataApplicantHolder
  incomes: SaveDataIncomeHolder
  livings: SaveDataLivingHolder
  loans: SaveDataLoanHolder
  questions: Questions
  advice: Advice[]
  follow: string
  comment: string
  proposal: Proposal
  declines: boolean
  insurances: Insurances
  locked: boolean
  contactInfo: [ContactInfo, ContactInfo]
}

export const SAVE_DATA_VERSION = 1

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public data$ = new BehaviorSubject<SaveData>(DataService.getEmptyObject())

  private saveData: SaveData = DataService.getEmptyObject()

  private customer: Customer | undefined

  constructor(
    private configService: ConfigService,
    private controlService: ControlService,
    private customerService: CustomerService,
    private kalpService: KalpService,
    private proposalService: ProposalService,
    private staticDataService: StaticDataService
  ) {
    this.customerService.currentCustomer$.pipe(
      switchMap((customer: Customer | undefined) => {
        this.customer = customer
        return kalpService.buildings$
      }),
      switchMap((buildings: KalpBuilding[]) => {
        this.saveData.livings.buildings = buildings
        return this.kalpService.scenarioChange$
      }),
      switchMap((scenario: Scenarios | null) => {
        this.saveData.scenario = scenario as Scenarios
        return this.kalpService.loanAmount$
      }),
      switchMap((amount: number) => {
        this.saveData.livings.seekedAmount = amount
        return this.staticDataService.questions$
      }),
      switchMap((questions: Questions) => {
        this.saveData.questions = questions
        return this.staticDataService.advice$
      }),
      switchMap((advice: Advice[]) => {
        this.saveData.advice = advice
        return this.staticDataService.comment$
      }),
      switchMap((comment: string) => {
        this.saveData.comment = comment
        return this.staticDataService.followAdvice$
      }),
      switchMap((follow: string) => {
        this.saveData.follow = follow
        return this.kalpService.kalpResult$ // This save guards against saving when no personnummer
      }),
      //filter((kalps: Kalp[]) => kalps?.[0] && Array.isArray(kalps[0].applicants)),
      debounceTime(1000),
      map((kalps: LegacyKalp[]) => {
        this.controlService.canSave$.next(kalps[0].applicants.length > 0)
      })
    ).subscribe()
  }

  public static getEmptyObject(): SaveData {
    return {
      contactInfo: [{email: '', mobile: ''}, {email: '', mobile: ''}],
      scenario: Scenarios.Purchase,
      applicants: {
        applicants: [{name: '', personNummer: ''} as any],
        childrenCount: null,
        children: [],
        coApplicant: false,
        declines: false,
        applicantSpouses: false,
        haveCar: null,
        carCount: 0,
        haveLeaseCar: null,
        leaseCarCost: undefined
      },
      incomes: {
        incomes: [{
          income: null,
          studyLoan: 0,
          taxFreeIncome: 0
        }],
        otherCosts: 0
      },
      livings: {
        seekedAmount: 0,
        buildings: []
      },
      loans: {
        privateLoans: [],
        otherMortgageLoans: []
      },
      questions: {
        q1: -1,
        q2: -1,
        q3: -1,
        q4: -1,
        q5: -1,
        q6: -1,
        hasNewQuestions: true
      },
      advice: [],
      follow: '',
      comment: '',
      proposal: {loanAmount: 0, loans: [], manual: false},
      declines: false,
      insurances: {
        informed: false,
        insurancesTitle: '',
        insurancesText: ''
      },
      locked: true
    }
  }


  public static loanFromLoan(loan: KalpLoan): SaveDataLoan {
    return {
      amount: loan.amount,
      isBlancoLoan: loan.isBlancoLoan,
      interestPercent: loan.interestPercent,
      mortgageAmount: loan.mortgageAmount,
      solve: loan.solve,
      property: loan.property,
      expires: loan.expires,
      pTerms: loan.terms
    }
  }

  public reset(): void {
    this.customer = undefined
    this.saveData = DataService.getEmptyObject()
    this.kalpService.reset()
    this.customerService.currentCustomer$.next(undefined)
    this.staticDataService.reset()
    this.proposalService.proposal$.next({
      loanAmount: 0,
      loans: [],
      manual: false
    })
    this.data$.next(this.saveData)
    this.controlService.step$.next(0)
    this.controlService.declines$.next(false)
    this.controlService.insurances$.next({
      informed: false,
      insurancesText: '',
      insurancesTitle: ''
    })
    this.controlService.locked$.next(false)
  }

  /**
   * This function loads and set the customer
   * @param id
   */
  public populateCustomer(id: string): void {
    this.customerService.getCustomer(id).pipe(
      filter((customer: Customer | undefined): customer is Customer => !!customer),
      switchMap((customer: Customer) => {
        this.reset()
        this.customer = customer
        const saveData = customer.saveData

        // Upgrade if not ng version
        if (saveData.version === undefined) {
          this.upgradeToNg(saveData)
        }

        /**
         * This is a migration for buildings that are not marked as primary.
         * Could possibly be removed sometime in March 2024 or so.
         */
        if (!saveData.livings.buildings.every(b => b.primary !== undefined)) {
          saveData.livings.buildings.forEach(b => {
            b.primary = b.heading === 'Nya bostaden' || b.heading === 'Uppgifter om bostaden'
          })

        }
        this.kalpService.scenarioChange$.next(saveData.scenario)
        this.data$.next(saveData)
        this.controlService.locked$.next(customer.saveData.locked)

        // Write protection if the document is printed before or send digitally
        if (customer.signatures) {
          this.controlService.locked$.next(customer.signatures[0] || customer.signatures[1])
        }

        // Write protection if current advisor is not the same as the advisor that created the advice
        this.configService.logInState$.pipe(first()).pipe(filter(Boolean)).subscribe({
          next: currentUser => {
            if (customer.user && currentUser.sId !== customer.user.sId) {
              this.controlService.locked$.next(true)
            }
          }
        })

        this.staticDataService.advice$.next(saveData.advice || [])
        this.staticDataService.followAdvice$.next(saveData.follow)

        if (saveData.contactInfo) {
          this.staticDataService.contactInfo$.next(saveData.contactInfo)
        } else {
          this.staticDataService.contactInfo$.next([{
            email: '',
            mobile: ''
          }, {email: '', mobile: ''}])
        }

        this.staticDataService.comment$.next(saveData.comment)
        this.customerService.currentCustomer$.next(this.customer)
        saveData.proposal.loans =
          saveData.proposal.loans.map((input: KalpLoan): KalpLoan => {
            const loan = new KalpLoan(input.amount)
            loan.setFromInput(input)
            return loan
          })
        this.kalpService.setOtherLoans(saveData.proposal)
        this.proposalService.proposal$.next(saveData.proposal)
        this.staticDataService.questions$.next(saveData.questions)

        /**
         * "Avböjer rådgivning" checkbox
         */
        this.controlService.decline(customer.saveData.declines)

        /**
         * insurances checkbox
         */
        this.controlService.insurances$.next(customer.saveData.insurances)

        return this.controlService.canSave$
      }),
      filter((save: boolean) => save),
      first()
    ).subscribe({
      next: () => {
        this.controlService.canSave$.next(false)
      }
    })
  }

  public save(): Observable<Customer> {
    return this.kalpService.scenarioChange$
      .pipe(
        filter((s: Scenarios | null): s is Scenarios => !!s),
        switchMap((scenario: Scenarios) => {
          this.saveData.scenario = scenario
          return this.proposalService.proposal$
        }),
        switchMap((proposal: Proposal) => {
          this.saveData.proposal = proposal
          return this.controlService.locked$
        }),
        switchMap((locked: boolean) => {
          this.saveData.locked = locked
          return this.controlService.declines$
        }),
        switchMap((decline: boolean) => {
          this.saveData.declines = decline
          return this.controlService.insurances$
        }),
        switchMap((insurance: Insurances) => {
          this.saveData.insurances = insurance
          return this.staticDataService.contactInfo$
        }),
        switchMap((contactInfo: [ContactInfo, ContactInfo]) => {
          this.saveData.contactInfo = contactInfo
          return this.kalpService.kalpResult$
        }),
        first(),
        switchMap((kalps: LegacyKalp[]) => {
          this.setApplicants(kalps[0])
          this.setIncomes(kalps[0])
          this.unReduceIncomes()
          this.setLoans(kalps[0])
          this.controlService.canSave$.next(false)
          // Make sure to set the version if anything is saved
          this.saveData.version = SAVE_DATA_VERSION
          if (this.customer) {
            this.customer.saveData = this.saveData
            return this.customerService.saveCustomer(this.customer)
          } else {
            // In the autumn of 2023, we have added new questions. Until we remove all legacy customers,
            // we need to ensure that newly onboarded customers are using the new questions
            this.saveData.questions.hasNewQuestions = true
            return this.customerService.createCustomer(this.saveData)
          }
        })
      )
  }

  /**
   * Upgrades the stored data to NG capable.
   * @param saveData
   * @private
   */
  private upgradeToNg(saveData: SaveData): void {

    saveData.applicants.children = []
    // Set children age to 3 which is the closest value to the legacy 3800
    if (saveData.applicants.childrenCount !== null) {
      for (let n = 0; n < saveData.applicants.childrenCount; n++) {
        saveData.applicants.children.push(3)
      }
    }

    /**
     * Set the cars as not present to avoid errors
     */
    saveData.applicants.haveCar = false
    saveData.applicants.haveLeaseCar = false

    // Set the Employer and other data on the applicants
    saveData.applicants.applicants.forEach((a: SaveDataApplicant) => {
      a.employer = 'Ej uppgett'
      a.hasSpouse = false
    })
  }

  /**
   * Called on Save data
   * @param kalp
   * @private
   */
  private setApplicants(kalp: LegacyKalp): void {
    this.saveData.applicants.applicants = kalp.applicants.map((applicant: KalpApplicant): SaveDataApplicant => ({
      name: applicant.name,
      personNummer: applicant.personNummer,
      retired: applicant.retired,
      akassa: applicant.akassa,
      employer: applicant.employer,
      reducedIncome: applicant.reducedIncome,
      hasSpouse: applicant.hasSpouse,
      spouse: applicant.spouse
    }))

    this.saveData.applicants.coApplicant = this.saveData.applicants.applicants.length > 1
    this.saveData.applicants.childrenCount = kalp.children.count
    this.saveData.applicants.children = kalp.childrenAges
    this.saveData.applicants.applicantSpouses = kalp.applicantSpouses
    this.saveData.applicants.haveCar = kalp.haveCar
    this.saveData.applicants.carCount = kalp.carCount
    this.saveData.applicants.haveLeaseCar = kalp.haveLeaseCar
    this.saveData.applicants.leaseCarCost = kalp.leaseCarCost
  }

  private setIncomes(kalp: LegacyKalp): void {
    this.saveData.incomes.incomes = kalp.incomes.map((income: KalpIncome) => ({
      income: income.income,
      taxFreeIncome: income.taxFreeIncome,
      studyLoan: income.studyLoan
    }))
    this.saveData.incomes.otherCosts = kalp.income.otherCosts
  }

  private unReduceIncomes(): void {
    this.saveData.applicants.applicants.forEach((applicant: SaveDataApplicant, index: number) => {
      if (applicant.reducedIncome) {
        /**
         * Using Math.round to handle potential decimals in (not liked by spbNumber).
         * While this works, it might be worth exploring a more efficient solution for precise value storage.
         */
        this.saveData.incomes.incomes[index].income = Math
          .round((this.saveData.incomes.incomes[index].income as number / 70)) * 100
      }
    })
  }

  private setLoans(kalp: LegacyKalp): void {
    this.saveData.loans.privateLoans = kalp.loans.loans
      .filter((loan: KalpLoan) => loan.isBlancoLoan)
      .map((loan: KalpLoan) => DataService.loanFromLoan(loan))
    this.saveData.loans.otherMortgageLoans = kalp.loans.loans
      .filter((loan: KalpLoan) => !loan.isBlancoLoan)
      .map((loan: KalpLoan) => DataService.loanFromLoan(loan))
  }
}
