import {formatNumber} from '@angular/common'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {MatCheckbox} from '@angular/material/checkbox'
import {Subscription} from 'rxjs'
import {filter, switchMap} from 'rxjs/operators'
import {KalpLoan} from '../../model/kalp-loan'
import {TermsTexts, TTermDuration, TTermText} from '../../model/terms-texts'
import {ControlService} from '../../services/control.service'
import {KalpService, LegacyKalp, Scenarios} from '../../services/kalp.service'

export interface ExistingLoanInput {
  amount: string
  interest: string
  mortgage: string
  terms: string
  expires: string
  solve: boolean
}

@Component({
  selector: 'spb-existing-display',
  templateUrl: './existing-display.component.html',
  styleUrls: ['./existing-display.component.scss'],
  imports: [MatCheckbox]
})
export class ExistingDisplayComponent implements OnInit, OnDestroy {

  public loans: ExistingLoanInput[] = []

  private scenario: Scenarios = Scenarios.Conditions
  private kalp$ = new Subscription()

  constructor(
    public controlService: ControlService,
    private kalpService: KalpService
  ) {
  }

  get conditions(): boolean {
    return this.scenario === Scenarios.Conditions
  }

  public ngOnInit(): void {
    this.kalp$ = this.kalpService.scenarioChange$.pipe(
      filter((scenarios: Scenarios | null): scenarios is Scenarios => scenarios !== null),
      switchMap((scenario: Scenarios) => {
        this.scenario = scenario
        return this.kalpService.kalpResult$
      })
    ).subscribe({
      next: (kalps: LegacyKalp[]) => {
        // In theory... all loans on the first kalp that is "mortgage" loan
        // are the ones we should display here.
        this.loans = kalps[0].loans.loans
          .filter((loan: KalpLoan) => !loan.isBlancoLoan).map((loan: KalpLoan): ExistingLoanInput => ({
            amount: formatNumber(loan.amount, 'fr', '1.0-0'),
            mortgage: formatNumber(loan.mortgageAmount, 'fr', '1.0-0'),
            interest: formatNumber(loan.interestPercent * 100, 'fr', '1.0-2'),
            terms: this.selectTerms(loan.terms as TTermDuration),
            expires: loan.changeDate,
            solve: loan.solve
          }))
      }
    })
  }

  public ngOnDestroy(): void {
    this.kalp$.unsubscribe()
  }

  private selectTerms(terms: TTermDuration): TTermText {
    return TermsTexts.getByMonths(terms).text
  }
}
