export const HOME_ROUTE_PATH = 'main'
export const LOGIN_ROUTE_PATH = 'login'
export const CUSTOMERS_ROUTE_PATH = 'customers'

/**
 * A list of colors to be used in various displays
 */
export const COLOR_LIST = [
  '#fedd46',
  '#00960a',
  '#7c9ed3',
  '#aa4586',
  '#a8763e',
  '#424874'
]

/**
 * Returns a UUID V4 string, 36 characters etc.
 *
 * @return {string} - The UUID V4 best effort randomized
 */
export const uuidV4 = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
