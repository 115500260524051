<div class="spb-step-mongo">
  @if (declines) {
    <ng-template>
      <div>
        <h2>{{ scenarioDescription }}</h2>
        <div class="declines">
          <p>{{ staticDataService.followAdvice$ | async }}</p>
        </div>
        <div *ngTemplateOutlet="insurances"></div>
      </div>
    </ng-template>
  }

  <ng-template #insurances>
    @if (controlService.insurances$ | async; as insurance) {
      <div>
        @if (insurance.informed) {
          <h2>{{ insurance.insurancesTitle }}</h2>
          <p>{{ insurance.insurancesText }}</p>
        }
      </div>
    }

  </ng-template>

  <div>
    <!--
      Title
      -->
    <div class="title">

      <h1>Dokumentation av Bolånerådgivning</h1>
      <h1>{{ date | date: 'yyyy-MM-dd' }}</h1>
    </div>
    <h2>Låntagare</h2>
    <div class="content">
      @for (suspect of suspects$ | async; track $index) {
        <div class="group">
          <div class="label">{{ suspect.name }}</div>
          <div class="value">{{ suspect.personNummer }}</div>
        </div>
      }
    </div>
    <p></p>
    <h2>Handläggare</h2>
    @if (configService.logInState$ | async; as user) {
      <div class="content">
        <div class="group">

          @if (controlService.locked$.value) {
            <div class="label">
              {{ customerService.currentCustomer$.value?.user?.name }}
            </div>
            <div class="label">
              {{ customerService.currentCustomer$.value?.user?.email }}
            </div>
          } @else {
            <div class="label">
              {{ user.name }}
            </div>
            <div class="label">
              {{ user.email }}
            </div>
          }
        </div>
      </div>
    }

    @if (!declines) {
      <div>
        <spb-summary-purpose
          [building]="building"
          [scenario]="scenario"
          [scenarioDescription]="scenarioDescription"
          (purposeResult)="purposeResult($event)"
        ></spb-summary-purpose>
        <h2>Så här tänker {{ plrDuNi.toLowerCase() }}
          kring {{ plrDittErt.toLowerCase() }} boende</h2>
        <div class="content">
          @if (!(staticDataService.questions$ | async)?.hasNewQuestions) {
            <ng-container>
              <div class="value">{{ q1 }}</div>
              <div class="value">{{ q2 }}</div>
            </ng-container>
          }
          @if ((staticDataService.questions$ | async)?.hasNewQuestions) {
            <ng-container>
              <div class="value">{{ q3 }}</div>
              <div class="value">{{ q4 }}</div>
              <div class="value">{{ q5 }}</div>
              <div class="value">{{ q6 }}</div>
            </ng-container>
          }
        </div>

        <h2>{{ plrDinEr }} ekonomiska situation</h2>
        <div class="legend">
          <spb-chart-legend></spb-chart-legend>
        </div>
        <div class="kalp-holder">
          @for (kalp of kalpSummary$ | async; track $index) {
            <div>
              <h4>{{ kalp.name }}</h4>
              <spb-chart [imageId]="'kalp' + $index" [kalp]="kalp"
                         spbChartDirective></spb-chart>
              <p>Inkomster: {{ kalp.totalIncome | number: '1.0-2':'fr' }} kr</p>
              <p>Kostnader: {{ kalp.totalCost | number: '1.0-2':'fr' }} kr</p>
              <p>Kvar att leva på: {{ kalp.kalp | number: '1.0-2':'fr' }} kr</p>
            </div>
          }

        </div>
        @if (reducedIncome) {
          <p>{{ reducedIncome }}</p>
        }

        <h2>Rekommendation ny portfölj</h2>
        <div class="portfolio">
          <spb-loan-distribution-chart [loans]="newLoans" imageId="dist"
                                       spbChartDirective></spb-loan-distribution-chart>
          <div class="fancy-row">
            @for (loan of newLoans; track $index) {
              <div [style.border-bottom-color]="colorList[$index]">
                {{ loan.ratio * 100 | number: '1.0-0':'fr' }} %,
                @if (loan.terms === 3) {
                  <span>3 månader</span>
                }
                @if (loan.terms === 12) {
                  <span>1 års </span>
                }
                @if (loan.terms === 24) {
                  <span>2 års</span>
                }
                @if (loan.terms === 36) {
                  <span>3 års</span>
                }
                @if (loan.terms === 48) {
                  <span>4 års</span>
                }
                @if (loan.terms === 60) {
                  <span>5 års</span>
                }
                räntebindning, f.n.
                {{ loan.interestPercent * 100 | number: '1.0-2':'fr' }} % ränta
              </div>
            }

          </div>
        </div>

        <p>Ränta och amortering {{ totalCost[0]| number: '1.0-2':'fr' }}
          kr/månad</p>
        <p>Ränta + 2% och amortering {{ totalCost[1]| number: '1.0-2':'fr' }}
          kr/månad</p>
        <p>{{ mortgageRequirementText }}</p>

        <h2>Rekommenderad lånefördelning</h2>
        <spb-loan-table [loanCost]="loanCost" [loans]="newLoans"
                        [scenarioChange]="scenarioChange"></spb-loan-table>

        <h2>Tidsaxel lån</h2>
        <spb-loan-overview [loans]="newLoans" imageId="timeline-print"
                           spbChartDirective></spb-loan-overview>

        <p class="minor">* Villkorsändringsdag för nytt lån är preliminär,
          fastställs på utbetalningsdag.</p>
        <p class="minor"><em>{{ mortgageDisclaimer }}</em></p>
        <h2>Portföljen passar {{ plrDigEr.toLowerCase() }} därför att</h2>
        <div>
          <ul>
            @for (advice of staticDataService.advice$ | async; track $index) {
              @if (!advice.deleted) {
                <li>{{ advice.advice }}</li>
              }
            }
          </ul>
        </div>

        <h2>Så här väljer {{ plrDuNi.toLowerCase() }} att göra med mina råd</h2>
        <p>{{ staticDataService.followAdvice$ | async }}</p>
        @if (comment) {
          <h2>Övriga kommentarer</h2>
        }
        <p style="white-space: pre-wrap;">{{ comment }}</p>

        <div *ngTemplateOutlet="insurances"></div>
      </div>
    }
    <h2>Godkännande</h2>
    <div class="content approvals">
      <mat-accordion>
        <mat-expansion-panel expanded="false">
          <mat-expansion-panel-header>Juridisk text</mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            @if (!declines) {
              <div>
                <p>Jag bekräftar att Sparbanken Syd, senast när information om
                  mina ekonomiska och personliga
                  förhållanden samt önskemål och särskilda krav inhämtats,
                  upplyst
                  om att banken erbjuder rådgivning om
                  bostadskrediter.</p>
                <p>Jag bekräftar vidare att jag, innan rådgivning lämnats, fått
                  information om det produktsortiment
                  rådgivningen utgått ifrån, att det inte utgått någon avgift
                  för
                  rådgivningen, och att rådgivningen inte
                  har varit oberoende.</p>
                <p>Jag har blivit informerad om vikten av att lämna korrekta och
                  fullständiga uppgifter särskilt när det
                  gäller mina ekonomiska och personliga förhållanden samt mina
                  önskemål och särskilda krav när det gäller
                  bolån. Jag bekräftar således att lämnade uppgifter är riktiga
                  och fullständiga.</p>
                <p>Jag har tagit del av rådgivningsdokumentationen och bekräftar
                  att den dokumenterar vilka råd som har
                  getts till mig och vad som i övrigt förekommit vid
                  rådgivningstillfället.</p>
                <p>Jag bekräftar att den rådgivning jag erhållit tagit hänsyn
                  till
                  mina ekonomiska och personliga
                  förhållanden samt mina önskemål och särskilda krav när det
                  gäller bolån. Rimlig hänsyn har också tagits
                  till risker som kan uppstå för mig under kreditavtalets
                  löptid.</p>
                <p>Jag är införstådd med att lämnad rekommendation grundar sig
                  på
                  den information som var tillgänglig för
                  banken vid tidpunkten för rådgivningen.</p>
                <p>Jag är införstådd med att de kalkyler som banken kan
                  presentera
                  grundar sig på antaganden om framtiden
                  t
                  ex vad gäller utvecklingen av samhällsekonomin och mina egna
                  framtida ekonomiska och personliga
                  förhållanden. Detta är omständigheter som vid tidpunkten för
                  rådgivningen är okända eller osäkra varför
                  jag också är införstådd med att dessa kalkyler inte ska tolkas
                  som några utfästelser från bankens sida.
                  Jag är vidare införstådd med att om förändring sker som inte
                  omfattas av kalkylen så kan utfallet bli
                  sämre för mig ekonomiskt än vad som framgår av
                  rådgivningsdokumentationen. Historisk utveckling är inte
                  någon garanti för framtida utfall.</p>
                <p>Jag är införstådd med att ränteskillnadsersättning kan utgå
                  om
                  bundna lån löses i förtid.</p>
                <p>Jag är införstådd med att skuldsättning alltid innebär visst
                  mått av risk.</p>
                <p>Jag är införstådd med att lämnad rådgivning inte innebär att
                  jag har beviljats bolån eller erhållit
                  lånelöfte, och att banken förbehåller sig rätten att helt
                  oberoende från rådgivningen besluta huruvida
                  bolån ska beviljas eller ej. Eventuellt avtal om bolån
                  omfattas
                  enbart av villkoren för bolånet och
                  lämnad
                  rådgivning är alltså inte en del av villkoren för eventuellt
                  bolån.</p>
                <p>Jag är medveten om att jag kommer få dokumentationen från
                  detta
                  rådgivningstillfälle skickat till mig
                  i internetbanken. För det fall jag saknar internetbanken får
                  jag
                  den istället som papperskopia.</p>
              </div>
            }
            @if (declines) {
              <div>
                <p>Jag har blivit informerad om att banken tillhandahåller
                  rådgivning avseende bolån. Jag bekräftar vidare
                  att jag har fått information om det produktsortiment bankens
                  rådgivning utgår ifrån, och att det inte
                  utgår någon avgift för rådgivningen.</p>
                <p>Jag bekräftar härmed att jag avböjer sådan rådgivning och att
                  jag inte heller har erhållit sådan.</p>
              </div>
            }
          </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel expanded="false">
          <mat-expansion-panel-header>Behandling av personuppgifter
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <p class="bold">Information om behandling av personuppgifter</p>
            <p>Information om Sparbanken Syds behandling av personuppgifter,
              vänligen se vår hemsida eller kontakta
              Kundcenter
              på
              0411- 82 20 00.</p>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div [ngClass]="{'disable-click-events': controlService.locked$ | async}">
      <spb-contact-holder
        (formStatusChanged)="formStatus = $event"></spb-contact-holder>
    </div>
    <div class="buttons">
      <button (click)="print()" mat-stroked-button>Skriv ut</button>
      <button (click)="sendIt()"
              [disabled]="!formStatus || (controlService.locked$ | async) === true"
              mat-stroked-button>
        Skicka för digital signatur
      </button>
      @if ((controlService.locked$ | async) === true) {
        <button (click)="unLockAdvice()"

                color="primary"
                mat-raised-button>
          Öppna för redigering
        </button>
      }
    </div>
    <div>
      <spb-fill-form></spb-fill-form>
    </div>


  </div>
  <div class="spb-step-info">
    <p>
      Ange <span class="spb-mongo-highlight">e-post</span> eller <span
      class="spb-mongo-highlight">mobiltelefonnummer (xxxx-xxxxxx)</span> för
      att kund ska få avisering om att det finns
      dokument att
      signera
    </p>
    <p><span class="spb-mongo-highlight">Villkorsändringsdag</span> för nytt lån
      är preliminär, fastställs på
      utbetalningsdag</p>
  </div>
</div>
