<div [ngClass]="{'protected': (controlService.locked$ | async) !==true}"><p
  class="protected-text">Skrivskyddad
  rådgivning</p></div>

<mat-stepper #thaStepper [linear]="testService.linear" class="stepper"
             labelPosition="bottom">
  <mat-step [completed]="steps[1]">
    <ng-template matStepLabel>Låntagare</ng-template>
    <ng-template matStepContent>
      <div [ngClass]="{'disable-click-events': controlService.locked$ | async}"
           class="margin-top-small">
        <h2>Rådgivning</h2>
        <spb-scenario></spb-scenario>

        <spb-general (stepValid)="stepValid($event, 1)"></spb-general>
        <div class="button-space">
        </div>
      </div>
      <div>
        <button [disabled]="!steps[1]" color="primary" mat-raised-button
                matStepperNext>Nästa
        </button>
      </div>

    </ng-template>
  </mat-step>
  <mat-step [completed]="steps[2]">
    <ng-template matStepLabel>Inkomster / Kostnader</ng-template>

    <div [ngClass]="{'disable-click-events': controlService.locked$ | async}"
         class="margin-top-small">
      <h2>Inkomster / Kostnader</h2>
      <spb-kalp (stepValid)="stepValid($event, 2)"></spb-kalp>
    </div>

    <div class="button-space">
      <div>
        <button color="primary" mat-stroked-button matStepperPrevious>Tillbaka
        </button>
      </div>
      <div>
        <button [disabled]="!steps[2]" color="primary" mat-raised-button
                matStepperNext>Nästa
        </button>
      </div>
    </div>
  </mat-step>
  <mat-step [completed]="steps[3]">
    <ng-template matStepLabel>Boende</ng-template>
    <div [ngClass]="{'disable-click-events': controlService.locked$ | async}"
         class="margin-top-small">
      <h2>Boende</h2>
      <spb-living (stepValid)="stepValid($event, 3)"></spb-living>
    </div>
    <div class="button-space">
      <div>
        <button color="primary" mat-stroked-button matStepperPrevious>Tillbaka
        </button>
      </div>
      <div>
        <button [disabled]="!steps[3]" color="primary" mat-raised-button
                matStepperNext>Nästa
        </button>
      </div>
    </div>
  </mat-step>
  <mat-step completed>
    <ng-template matStepLabel>Befintliga lån</ng-template>
    <div [ngClass]="{'disable-click-events': controlService.locked$ | async}"
         class="margin-top-small">
      <spb-existing-loan></spb-existing-loan>
    </div>
    <div class="button-space">
      <div>
        <button color="primary" mat-stroked-button matStepperPrevious>Tillbaka
        </button>
      </div>
      <div>
        <button color="primary" mat-raised-button matStepperNext>Nästa</button>
      </div>
    </div>
  </mat-step>
  <mat-step [completed]="steps[5]">
    <ng-template matStepLabel>Hur tänker {{ plrDuNi.toLowerCase() }}
      kring {{ plrDittErt.toLowerCase() }} boende
    </ng-template>
    <div [ngClass]="{'disable-click-events': controlService.locked$ | async}"
         class="margin-top-small">
      <spb-questions (stepValid)="stepValid($event, 5)"></spb-questions>
    </div>
    <div class="button-space">
      <div>
        <button color="primary" mat-stroked-button matStepperPrevious>Tillbaka
        </button>
      </div>
      <div>
        <button [disabled]="!steps[5]" color="primary" mat-raised-button
                matStepperNext>Nästa
        </button>
      </div>
    </div>
  </mat-step>
  <mat-step completed>
    <ng-template matStepLabel>KALP</ng-template>
    <ng-template matStepContent>
      <div class="legend margin-top-small">
        <spb-chart-legend></spb-chart-legend>
      </div>
      <spb-piechart></spb-piechart>
      @if (kalpService.kalpResult$ | async) {
        <spb-kalp-result></spb-kalp-result>
      }
      <div class="button-space">
        <div>
          <button color="primary" mat-stroked-button matStepperPrevious>
            Tillbaka
          </button>
        </div>
        <div>
          <button color="primary" mat-raised-button matStepperNext>Nästa
          </button>
        </div>
      </div>
    </ng-template>
  </mat-step>
  <mat-step [completed]="steps[7]">
    <ng-template matStepLabel>Förslag</ng-template>
    <div class="margin-top-small">
      <spb-proposal (stepValid)="stepValid($event, 7)"></spb-proposal>
    </div>
    <div class="button-space">
      <button color="primary" mat-stroked-button matStepperPrevious>Tillbaka
      </button>
      <button [disabled]="!steps[7]" color="primary" mat-raised-button
              matStepperNext>Nästa
      </button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Sammanställning</ng-template>
    <div class="margin-top-small">
      <spb-summary></spb-summary>
    </div>
    <div class="button-space">
      @if (hasDeclined) {
        <button (click)="decline()" color="warn" mat-stroked-button>Kund avböjer
          inte
        </button>
      }
      <button color="primary" mat-stroked-button matStepperPrevious>Tillbaka
      </button>
    </div>
  </mat-step>
</mat-stepper>
