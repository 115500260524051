import {Component, Input, OnChanges} from '@angular/core'
import {PieChartModule} from '@swimlane/ngx-charts'
import {KalpResult} from '../../../model/kalp-result'

export interface IChartDataHolder {
  chart: IChartValue[]

  color: any
}

export interface IChartValue {

  name: string

  value: number

}

@Component({
  selector: 'spb-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  imports: [PieChartModule]
})
export class ChartComponent implements OnChanges {

  /**
   * We need a Kalp as input to create a chart
   */
  @Input() kalp: KalpResult = new KalpResult()

  public reverseColor = {
    domain: ['#be0005', '#fedd46', '#7c9ed3']
  }

  public baseColor = {
    domain: ['#fedd46', '#7c9ed3', '#008307']
  }

  public holder: IChartDataHolder = {
    chart: [],
    color: this.baseColor
  }

  public ngOnChanges() {

    this.holder = {
      chart: [],
      color: this.baseColor
    }
    const chart: IChartValue[] = [
      {
        name: 'Inkomster före skatt',
        value: this.kalp.totalIncome
      },
      {
        name: 'Kostnader',
        value: this.kalp.totalCost
      }
    ]
    const surplus = {
      name: 'Överskott/Underskott',
      value: this.kalp.kalp
    }
    if (this.kalp.kalp < 0) {
      surplus.value *= -1
      chart.unshift(surplus)
      this.holder.color = this.reverseColor
    } else {
      chart.push(surplus)
    }
    this.holder.chart = chart
  }
}
