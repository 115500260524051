<mat-expansion-panel [expanded]="!advice.valid">
  <mat-expansion-panel-header>
    <mat-panel-title>
      @if (!advice.valid) {
        <span>Så här väljer {{ plrDuNi.toLowerCase() }}
          att göra med mina råd</span>
      }
      @if (advice.valid) {
        <span>{{ advice.value }}</span>
      }
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div [ngClass]="{'disable-click-events': controlService.locked$ | async}">
    @if (showOptions) {
      <mat-radio-group (change)="approve($event)"
                       [formControl]="advice"
                       aria-label="Follow advice"
                       color="primary"
                       ngDefaultControl>
        <mat-radio-button
          value="{{plrDuNi}} väljer att följa mina råd">{{ plrDuNi }} väljer att
          följa mina råd
        </mat-radio-button>
        <mat-radio-button
          value="{{plrDuNi}} väljer att inte följa mina råd">{{ plrDuNi }}
          väljer
          att inte följa mina råd
        </mat-radio-button>
      </mat-radio-group>
    }
  </div>
</mat-expansion-panel>
