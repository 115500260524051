<div class="chart-size">
  <ngx-charts-pie-chart
    [doughnut]=false
    [gradient]="false"
    [labels]=false
    [legend]=false
    [results]="holder.chart"
    [scheme]="holder.color"
  >
  </ngx-charts-pie-chart>
</div>
