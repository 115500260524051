<div class="timeline-print">
  <div class="timeline">

    @for (loan of modifiedLoans; let i = $index; track i) {
      <div class="dot" [style.left]="loan.position + '%'"
           [style.background-color]="colorList[i]"></div>
      <div class="loan-info" [style.left]="loan.info + '%'"
           [style.border]="'2px solid' + colorList[i]">
        <p>{{ loan.amount | number }} kr</p>
        <p>
          @if (loan.terms > 3) {
            {{ loan.terms / 12 + 'år' }}
          } @else {
            {{ loan.terms + 'mån' }}
          }
        </p>
        <p>
          @if (loan.expires) {
            {{ loan.expires | date: 'yyyy-MM-dd' }}
          } @else {
            Ej valt
          }
        </p>
      </div>
    }
    <div class="white-arrow"></div>
    <div class="arrow"></div>
  </div>
</div>