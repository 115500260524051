import {Component} from '@angular/core'
import {MatCheckbox} from '@angular/material/checkbox'
import {TestService} from '../../services/test.service'

@Component({
  selector: 'spb-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  imports: [MatCheckbox]
})
export class TestComponent {
  constructor(
    public testService: TestService
  ) {
  }

  public setLinear(): void {
    this.testService.linear = !this.testService.linear
  }
}
