<ul>
  @for (advice of staticDataService.advice$ | async; track $index) {
    <li>
    <span class="advice">
      <span [ngClass]="{ 'deleted' : advice.deleted }"
            class="advice-item">{{ advice.advice }}</span>

      @if (!advice.deleted) {
        <button (click)="toggleAdvice(advice)" color="warn" mat-stroked-button>
          <span class="material-symbols-outlined">delete</span>
        </button>
      }

      @if (advice.deleted) {
        <button (click)="toggleAdvice(advice)" color="accent"
                mat-stroked-button>
          <span class="material-symbols-outlined">check</span>
        </button>
      }
    </span>
    </li>
  }
</ul>
<div class="additional-advice-holder">
  <mat-form-field>
    <mat-label>Ytterligare motivering</mat-label>
    <input [formControl]="customAdviceControl" matInput name="advice">
  </mat-form-field>
  <button (click)="addAdvice()" color="accent" mat-stroked-button>Lägg till
  </button>
</div>
