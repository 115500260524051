import {DecimalPipe} from '@angular/common'
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'
import {
  FormArray,
  FormControl,
  FormControlStatus,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatOption} from '@angular/material/core'
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {Car, Child} from '@sparbanken-syd/kalpylator'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {Subscription} from 'rxjs'
import {filter, tap} from 'rxjs/operators'
import {DataService, SaveData} from '../../services/data.service'
import {KalpService} from '../../services/kalp.service'
import {NGService} from '../../services/ng.service'
import {addOrRemoveValidators} from '../1-general/general.component'

@Component({
  selector: 'spb-household',
  templateUrl: './household.component.html',
  imports: [ReactiveFormsModule, MatFormField, MatLabel, MatSelect, MatOption, MatError, MatInput, DecimalPipe, FormatNumberDirective]
})
export class HouseholdComponent implements OnInit, OnDestroy {
  @Output() valid = new EventEmitter<boolean>

  /**
   * Keep this external for reasons
   */
  public childrenFormArray = new FormArray<any>([])

  public form = new FormGroup({
    childrenCount: new FormControl<number | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    children: this.childrenFormArray,
    haveCar: new FormControl<boolean | null>(null, {validators: [Validators.required]}),
    carCount: new FormControl<number>(0),
    haveLeaseCar: new FormControl<boolean | null>(null, {validators: Validators.required}),
    leaseCarCost: new FormControl<number | null>(null, {validators: Validators.required})
  })

  private data$ = new Subscription()

  constructor(private ngService: NGService,
              private kalpService: KalpService,
              private dataService: DataService) {
  }

  get childrenArray(): FormControl[] {
    return this.childrenFormArray.controls as FormControl[]
  }

  public ngOnInit(): void {
    this.form.statusChanges.pipe(
      tap(() => this.valid.emit(false)),
      filter((v: FormControlStatus) => v === 'VALID')
    ).subscribe({
      next: () => {
        const value = this.form.getRawValue()
        // Children here is an array of numbers
        value.children = this.childrenFormArray.getRawValue()

        if (value.childrenCount as any === 'Inga') {
          value.childrenCount = 0
        }
        // Emit old shite
        this.kalpService.kalpData$.next(value)

        // Emit cars to NG
        const cars: Car[] = []
        if (value.haveCar && value.carCount !== null) {
          for (let i = 0; i < value.carCount; i++) {
            cars.push({
              id: i + '',
              type: 'cars',
              carType: 'OWN'
            })
          }
        }
        if (value.haveLeaseCar && value.leaseCarCost !== null) {
          cars.push({
            id: 'lease',
            type: 'cars',
            carType: 'LEASE',
            monthlyCost: value.leaseCarCost
          })
        }
        this.ngService.ngCars$.next(cars)
        this.valid.emit(true)
      }
    })

    this.data$ = this.dataService.data$
      .subscribe({
        next: (raw: SaveData) => {
          const value: SaveData = JSON.parse(JSON.stringify(raw))
          // Before patching, we need to adjust the form to accommodate children ages
          // But do not set it if the value is zero, b/c that makes it set w/o actual selection
          if (value.applicants.childrenCount !== 0) {
            this.adjustChildrens(value.applicants.childrenCount)
          }

          this.form.patchValue(value.applicants)
        }
      })

    this.form.controls.childrenCount.valueChanges.pipe(
      filter((v: number | null): v is number => v !== null)
    ).subscribe({
      next: this.adjustChildrens
    })

    /**
     * Children can be emitted as is as soon as they are set.
     */
    this.childrenFormArray.valueChanges.pipe().subscribe({
      next: (value: number[]) => {
        const children: Child[] = value.map((age: number): Child => ({
          type: 'children',
          id: '',
          age: Math.floor(age) // Remember that it can be 0.1
        }))
        this.ngService.ngChildren$.next(children)
      }
    })

    this.form.controls.haveCar.valueChanges
      .pipe(filter((v: boolean | null): v is boolean => v !== null))
      .subscribe({
        next: (val: boolean) => {
          addOrRemoveValidators(val, this.form.controls.carCount, Validators.required)
        }
      })

    this.form.controls.haveLeaseCar.valueChanges
      .pipe(filter((v: boolean | null): v is boolean => v !== null))
      .subscribe({
        next: (val: boolean) => {
          addOrRemoveValidators(val, this.form.controls.leaseCarCost, Validators.required)
        }
      })
  }

  public ngOnDestroy(): void {
    this.data$.unsubscribe()
  }

  public childrenCompare = (optionValue: any, controlValue: any): boolean => {
    if (controlValue === null) {
      return false
    }
    if (optionValue === 'Inga' && controlValue === 0) {
      return true
    }
    return optionValue === controlValue
  }

  private adjustChildrens = (count: number | string | null): void => {
    if (typeof count === 'string' || count === null) {
      count = 0
    }
    while (this.childrenFormArray.length > count) {
      this.childrenFormArray.removeAt(this.childrenFormArray.length - 1)
    }
    while (this.childrenFormArray.length < count) {
      this.childrenFormArray.push(new FormControl<number | null>(null, {validators: [Validators.required]}))
    }
  }

}
