/**
 * Let us create a helper class for getting the display values.
 */
import {getEmptyLegacyKalp, LegacyKalp} from '../services/kalp.service'

export class KalpResult {
  /**
   * Name is used for some displays
   */
  public name = ''

  /**
   * Income from salary/pension/other
   */
  private income = 0

  /**
   * Additional incomes excluded from tax
   */
  private taxFreeIncome = 0

  /**
   * Children benefits including
   */
  private childrenBenefit = 0

  /**
   * The cost of having childrens
   */
  private childrenCost = 0

  /**
   * Tax returns are money back and normally counted on the income side
   */
  private taxReturn = 0

  /**
   * Living expense is the "core" living expense set by "schablon"
   */
  private coreLivingExpense = 0

  /**
   * You can enter "additional" costs for the house hold
   */
  private otherCosts = 0
  /**
   * Loan costs are loans w/o study loan
   */
  private loanCosts = 0

  /**
   * Study loans are loans/costs separate from the other loans
   */
  private studyLoans = 0

  /**
   * cost of buildings are run costs for all buildings
   */
  private costOfBuildings = 0

  /**
   * Tax is the tax paid on income
   */
  private totalTax = 0

  /**
   * Cost of all cars combined
   */
  private costOfCars = 0

  constructor(kalp: LegacyKalp = getEmptyLegacyKalp()) {
    this.setFromKalp(kalp)
  }

  /**
   * This is total income after tax
   */
  get totalIncome(): number {
    return this.income + this.taxFreeIncome + this.childrenBenefit
  }

  /**
   * Currently they want living expense + children!
   */
  get livingExpense(): number {
    return this.coreLivingExpense + this.childrenCost
  }

  /**
   * Living cost is the cost of houses + loans?
   */
  get livingCost(): number {
    return this.costOfBuildings + this.loanCosts
  }

  /**
   * Total costs are calculated as the cost of all sorts of
   * costs, including living expense but minus taxReturn!
   */
  get totalCost(): number {
    return this.studyLoans +
      this.otherCosts +
      this.loanCosts +
      this.costOfBuildings +
      this.costOfCars +
      this.totalTax +
      this.livingExpense
  }

  /**
   * Direct costs are the costs excluding living and loans.
   */
  get directCosts(): number {
    return this.totalTax +
      this.studyLoans +
      this.otherCosts +
      this.costOfCars
  }

  get kalp(): number {
    return this.totalIncome - this.totalCost
  }

  public setFromKalp(input: LegacyKalp): void {
    // Base data
    this.name = input.name
    // Inbound money
    this.income = input.income.income
    this.taxFreeIncome = input.income.taxFreeIncome || 0
    this.childrenBenefit = input.children.income || 0 // Benefit + benefit extra.
    this.taxReturn = input.loans.taxReturn || 0 // The total sum of tax return

    // Outbound money
    this.childrenCost = input.children.cost || 0
    this.coreLivingExpense = input.livingExpense || 0
    this.otherCosts = input.income.otherCosts || 0
    this.studyLoans = input.income.studyLoan || 0
    this.loanCosts = input.loans.cost || 0
    this.totalTax = input.income.tax || 0
    this.costOfBuildings = input.costOfBuildings || 0
    this.costOfCars = input.costOfCars
  }
}

