import {formatDate} from '@angular/common'
import {Loan} from '@sparbanken-syd/kalpylator'
import {uuidV4} from '../application/data-types'
import {TermsTexts} from './terms-texts'

export class KalpLoan {

  public id: string = uuidV4()

  public interestPercent: number = TermsTexts.getThreeMonth().interestPercent

  public mortgagePercent = 0

  /**
   * MONTHLY mortgage in SEK.
   */
  public mortgageAmount = 0

  /**
   * The loan should be solved
   */
  public solve = false

  /**
   * Is this a new loan?
   */
  public new = false

  /**
   * Active is set if this loan is part of the current
   * rådgivning.
   */
  public active = false

  /**
   * If this is a blanco loan it should be handled differently?
   * Or is it just an indicator?
   */
  public isBlancoLoan = false

  /**
   * This can be set if this is part of a bunch of loans
   */
  public ratio = 1

  /**
   * An optional reference to a building that this loan belongs to?
   */
  public property: string | undefined

  /**
   * The date when this loan ends.
   */
  public expires = 0

  /**
   * Terms is the number of months this loan is running on, 3, 12, 24, 36, 60 etc.
   */
  private pTerms = 3

  /**
   * Returns the interest amount in SEK / month
   */
  get interestAmount(): number {
    return this.amount * this.interestPercent / 12
  }

  get cost(): number {
    return this.mortgageAmount + this.interestAmount
  }

  get changeDate(): string {
    return this.expires ? formatDate(this.expires, 'yyyy-MM-dd', 'fr', 'Europe/Stockholm') : ''
  }

  get terms(): number {
    return this.pTerms
  }

  set terms(terms: number) {
    this.pTerms = terms
    // Timestamp for the expiration date
    this.expires = this.modifyExpirationDate(this.pTerms, this.new)
  }

  /**
   * Create a new loan
   * @param amount - The total amount of the loan
   * @param newLoan - Set to true if this should be "true"
   */
  constructor(
    public amount: number,
    newLoan = false
  ) {
    this.new = newLoan
  }

  public modifyExpirationDate(terms: number, newLoan: boolean): number {
    // This is a special Johanna case, because new 3 months loans should have 12 months terms
    if (terms === 3 && newLoan) {
      terms = 12
    }

    const currentDate = new Date()
    const dayOfMonth = currentDate.getDate()
    const expirationDate = new Date(currentDate) // Create a copy of today's date for calculation
    expirationDate.setMonth(expirationDate.getMonth() + terms) // Add the number of months

    // If today is the 16th or later, adjust the expiration date to the next month
    if (dayOfMonth >= 16) {
      expirationDate.setMonth(expirationDate.getMonth() + 1)
    }

    // Set the expiration day to the 1st of the month
    expirationDate.setDate(1)

    // Set hours, minutes, seconds, and milliseconds to be 00:00:00 GMT+0000
    expirationDate.setHours(1, 0, 0, 0)

    return expirationDate.getTime()
  }

  public setFromInput(input: KalpLoan): void {
    Object.assign(this, input)
    this.setMortgageAmount(input.mortgageAmount)
  }

  public setMortgagePercent(percent: number): void {
    this.mortgagePercent = percent
    this.mortgageAmount = Math.ceil(this.amount * this.mortgagePercent / 12)
  }

  public setMortgageAmount(mortgage: number): void {
    this.mortgageAmount = mortgage
    this.mortgagePercent = mortgage * 12 / this.amount
  }

  /**
   * This is used to import loans saved in DataStore
   * @param input
   */
  public setLoanFromKalpInput(input: Loan): void {
    const blancoLoans = ['BLANCO', 'CREDIT', 'BORGEN']
    this.interestPercent = input.interestRate > 0 ? input.interestRate : 0
    this.mortgageAmount = input.mortgage > 0 ? input.mortgage : 0
    this.property = input.propertyId
    this.isBlancoLoan = blancoLoans.includes(input.loanType)
    this.solve = !input.keep
    this.expires = input.expires ?? 0
    this.pTerms = input.terms ?? 3
  }
}
