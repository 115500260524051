<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>KALP</mat-panel-title>
  </mat-expansion-panel-header>
  <div class="kalp">
    @for (kd of kalpService.kalpResult$ | async; track $index) {
      <div>
        <h4>{{ kd.name }}</h4>
        <p>{{ kd.kalp | number: '1.0-0' : 'fr' }}</p>
      </div>
    }
  </div>

</mat-expansion-panel>
