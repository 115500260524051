import {AsyncPipe, DecimalPipe} from '@angular/common'
import {Component} from '@angular/core'
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion'
import {KalpService} from '../../services/kalp.service'

@Component({
  selector: 'spb-mini-kalp',
  templateUrl: './mini-kalp.component.html',
  styleUrls: ['./mini-kalp.component.scss'],
  imports: [MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, AsyncPipe, DecimalPipe]
})
export class MiniKalpComponent {

  constructor(
    public kalpService: KalpService
  ) {
  }
}
