<div class="legend">
  <div>
    <div class="box yellow"></div>
    <div>Inkomster före skatt</div>
  </div>
  <div>
    <div class="box blue"></div>
    <div>Kostnader</div>
  </div>
  <div>
    <div class="box green"></div>
    <div>Överskott</div>
  </div>
  <div>
    <div class="box red"></div>
    <div>Underskott</div>
  </div>
</div>
