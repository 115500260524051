import {AsyncPipe, NgClass} from '@angular/common'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {Subscription} from 'rxjs'
import {filter, first, switchMap} from 'rxjs/operators'
import {Advice} from '../../model/advice'
import {KalpLoan} from '../../model/kalp-loan'
import {Plural} from '../../model/plural'
import {ControlService} from '../../services/control.service'
import {KalpService, LegacyKalp, Scenarios} from '../../services/kalp.service'
import {Questions} from '../../services/proposal.service'
import {StaticDataService} from '../../services/static-data.service'

@Component({
  selector: 'spb-advice',
  templateUrl: './advice.component.html',
  styleUrls: ['./advice.component.scss'],
  imports: [NgClass, MatButton, MatFormField, MatLabel, MatInput, ReactiveFormsModule, AsyncPipe]
})
export class AdviceComponent extends Plural implements OnInit, OnDestroy {

  public customAdviceControl = new FormControl()

  private questions: Questions = {
    q1: -1,
    q2: -1,
    q3: -1,
    q4: -1,
    q5: -1,
    q6: -1
  }

  private loans: KalpLoan[] = [{} as any]

  private adviceList: Advice[] = [{} as any]

  private scenario: Scenarios = Scenarios.Purchase

  /**
   * Is there surplus in KALP after 2% increase in interest rate?
   * @private
   */
  private twoPercent = 0

  /**
   * Are all loans fixed interest loans or not?
   */
  private fixedOnly = false

  /**
   * If all loan have same terms.
   */
  private allSame = false

  private declines = false

  private decline$ = new Subscription()

  private subs$ = new Subscription()

  private advice$ = new Subscription()

  constructor(
    public override staticDataService: StaticDataService,
    protected kalpService: KalpService,
    private controlService: ControlService
  ) {
    super(staticDataService)
  }

  ngOnInit(): void {
    this.init()
    this.subs$ = this.kalpService.scenarioChange$
      .pipe(
        filter((scenarios: Scenarios | null): scenarios is Scenarios => scenarios !== null),
        switchMap((scenario: Scenarios) => {
          this.scenario = scenario
          return this.staticDataService.questions$
        }),
        switchMap((questions: Questions) => {
          this.questions = questions
          return this.kalpService.kalpResult$
        }),
        switchMap((kalps: LegacyKalp[]) => {
          this.twoPercent = kalps[2].kalp
          return this.staticDataService.newLoans$
        })
      )
      .subscribe({
        next: (loans: KalpLoan[]) => {
          this.loans = loans
          this.analyze()
        }
      })

    this.advice$ = this.staticDataService.advice$.subscribe({
        next: (advice: Advice[]) => this.adviceList = advice
      }
    )

    this.decline$ = this.controlService.declines$.subscribe({
      next: (declines: boolean) => {
        this.declines = declines
        this.adviceList = this.adviceList.filter((advice: Advice) => advice.manual)
        this.staticDataService.advice$.next(this.adviceList)
      }
    })
  }

  public ngOnDestroy(): void {
    // Kill the parent sub
    this.destroy()
    this.subs$.unsubscribe()
    this.advice$.unsubscribe()
    this.decline$.unsubscribe()
  }

  public addAdvice(): void {
    this.staticDataService.advice$.pipe(
      first()
    ).subscribe({
      next: (adviceList: Advice[]) => {
        adviceList.push({
          advice: this.customAdviceControl.value,
          deleted: false,
          manual: true
        })
        this.customAdviceControl.setValue('', {emitEvent: false})
        this.staticDataService.advice$.next(adviceList)
      }
    })
  }

  public toggleAdvice(advice: Advice): void {
    advice.deleted = !advice.deleted
    this.staticDataService.advice$.pipe(
      first()
    ).subscribe({
      next: (adviceList: Advice[]) => {
        this.staticDataService.advice$.next(adviceList)
      }
    })
  }

  private advicesForNewQuestions(adviceList: Advice[]) {
    const newLoans = this.loans.filter(loan => loan.new)

    // Create variables to calculate the total amount for loans with 3 months and not 3 months.
    let totalAmount3Months = 0
    let totalAmountNot3Months = 0

    // Iterate list of loans
    newLoans.forEach(loan => {
      if (loan.terms === 3) {
        totalAmount3Months += loan.amount
      } else {
        totalAmountNot3Months += loan.amount
      }
    })

    if (newLoans.length === 1) {
      if (newLoans[0].terms === 3) {
        adviceList.push(new Advice(
          `Baserat på hur ${this.plrDuNi.toLowerCase()} bedömer ${this.plrDinEr.toLowerCase()} framtida ekonomi föreslår jag att ` +
          `${this.plrDuNi.toLowerCase()} väljer en 3-mån räntebindning ("rörlig ränta")`))
      } else {
        adviceList.push(new Advice(
          `Baserat på hur ${this.plrDuNi.toLowerCase()} bedömer ${this.plrDinEr.toLowerCase()} framtida ekonomi ` +
          `föreslår jag att ${this.plrDuNi.toLowerCase()} binder räntan på ${this.plrDittErt.toLowerCase()} lån`))
      }
    } else if (totalAmount3Months > 0) {
      if (totalAmount3Months > totalAmountNot3Months) {
        adviceList.push(new Advice(`Baserat på hur ${this.plrDuNi.toLowerCase()} bedömer ` +
          `${this.plrDinEr.toLowerCase()} framtida ekonomi föreslår jag att ${this.plrDuNi.toLowerCase()} väljer ` +
          `övervägande 3-mån räntebindning ("rörlig ränta")`))
      } else {
        adviceList.push(new Advice(`Baserat på hur ${this.plrDuNi.toLowerCase()} bedömer ` +
          `${this.plrDinEr.toLowerCase()} framtida ekonomi föreslår jag att ${this.plrDuNi.toLowerCase()} väljer en ` +
          `blandning av 3-mån räntebindning ("rörlig ränta") och en längre räntebindning`))
      }
    }
  }

  private analyze(): void {
    const manual: Advice[] = this.adviceList.filter((a: Advice) => a.manual)
    const adviceList: Advice[] = []

    if (this.questions.hasNewQuestions) {
      this.advicesForNewQuestions(adviceList)
    }

    if (!this.handleCornerCases(adviceList)) {

      this.sortOutTheNumberOfLoans(adviceList)

      this.setQuestionBasedAdvice(adviceList)

      this.fixedOnly = this.loans.every((loan: KalpLoan) => loan.terms !== 3)

      if (this.twoPercent < 0 && !this.questions.hasNewQuestions) {
        adviceList.push(new Advice(`Baserat på ${this.plrDinEr.toLowerCase()} ekonomi anser jag att` +
          ` ${this.plrDuNi.toLowerCase()} bör binda hela lånet`))
      }

      if (this.loans.length > 1 && this.twoPercent < 0 && !this.fixedOnly && this.questions.hasNewQuestions) {
        adviceList.push(new Advice(`Baserat på ${this.plrDinEr.toLowerCase()} ekonomi anser jag att` +
          ` ${this.plrDuNi.toLowerCase()} bör binda delar av lånebeloppet`))
      }

      if (this.twoPercent < 0 && this.fixedOnly && this.questions.hasNewQuestions) {
        adviceList.push(new Advice(`Med hänsyn till ${this.plrDinEr.toLowerCase()} ekonomi anser jag att` +
          ` ${this.plrDuNi.toLowerCase()} bör binda hela lånet`))
      }

      if (!this.fixedOnly) {
        adviceList.push(new Advice(`Min bedömning är att ${this.plrDinEr.toLowerCase()} ekonomi tål variation i boendekostnaden`))
      }

      if (!this.fixedOnly) {
        adviceList.push(new Advice(`${this.plrDuNi} har möjlighet att extraamortera på rörliga lån`))
      }

    }


    this.adviceList = adviceList.concat(manual)
    this.staticDataService.advice$.next(this.adviceList.filter((advice: Advice) =>
      advice.manual || !this.declines
    ))
  }

  private sortOutTheNumberOfLoans(adviceList: Advice[]): void {
    this.allSame = new Set(this.loans.map((l: KalpLoan) => l.terms)).size === 1
    if (!this.allSame) {
      adviceList.push(new Advice(
        `Genom att binda på olika bindningstider påverkas ${this.plrDuNi.toLowerCase()} inte lika` +
        ` mycket av ränteläget när de olika bindningstiderna löper ut`))
    }

    // Another special case where number of loans should not be set if the scenario is conditions.
    if (this.scenario !== Scenarios.Conditions) {
      if (this.loans.length === 1) {
        adviceList.push(new Advice(`Med hänsyn tagen till lånets storlek att det är lämpligt att ha det som ett lån`))
      } else {
        adviceList.push(new Advice(`Med hänsyn tagen till lånets storlek är det ` +
          `lämpligt att dela upp det på ${this.loans.length === 2 ? 'två' : 'tre'} lånedelar`))
      }
    }
  }

  private setQuestionBasedAdvice(adviceList: Advice[]): void {
    if (this.questions.q1 === 3) {
      adviceList.push(new Advice(`${this.plrDuNi} planerar att bo kvar i detta boende i ca 3 år`))
    }

    if (this.questions.q1 === 5) {
      adviceList.push(new Advice(`${this.plrDuNi} planerar att bo kvar i detta boende i 3 - 5 år `))
    }

    if (this.questions.q1 === 10) {
      adviceList.push(new Advice(`${this.plrDuNi} planerar att bo kvar i detta boende minst 5 år `))
    }

    //
    // Q 2
    //
    if (this.questions.q2 === 0) {
      adviceList.push(new Advice(`Det är viktigt för ${this.plrDigEr} att kunna ` +
        `förutse ${this.plrDinEr.toLowerCase()} boendekostnad över tid`))
      adviceList.push(new Advice(`${this.plrDuNi} vill inte att ${this.plrDinEr.toLowerCase()} ` +
        `räntekostnad stiger oförutsett`))
    }

    if (this.questions.q2 === 1) {
      adviceList.push(new Advice(`${this.plrDuNi} vill inte att ${this.plrDinEr.toLowerCase()}` +
        ` boendekostnad varierar för mycket över tid`))
    }
    if (this.questions.q2 === 2 && this.twoPercent > 0 && !this.fixedOnly) {
      adviceList.push(new Advice(`${this.plrDuNi} är ${this.plrBekvamBekvama} med att boendekostnaden varierar över tid`))
    }
  }

  private handleCornerCases(adviceList: Advice[]): boolean {
    if (this.questions.q6 === 1) {
      adviceList.push(new Advice(`${this.plrDuNi} planerar att bo i detta boende endast under en kortare period`))
      adviceList.push(new Advice(`${this.plrDuNi} har möjlighet att extraamortera på rörliga lån`))
      return true
    }

    if (this.loans.length === 1 && this.loans[0].terms === 3 && !this.questions.hasNewQuestions) {

      adviceList.push(new Advice(`Med hänsyn till lånebeloppet och ${this.plrDinEr.toLowerCase()} ekonomi föreslår jag ett ` +
        `lån med 3-månaders ränta som ${this.plrDuNi.toLowerCase()} kan binda när ${this.plrDuNi.toLowerCase()} vill`))

      if (this.questions.q2 === 2 && this.twoPercent > 0) {
        adviceList.push(new Advice(`${this.plrDuNi} är ${this.plrBekvamBekvama} med att boendekostnaden varierar över tid`))
      }
      if (this.twoPercent > 0) {
        adviceList.push(new Advice(`Min bedömning är att ${this.plrDinEr.toLowerCase()} ekonomi tål variation i boendekostnaden`))
      }
      return true
    }

    if (this.questions.q1 === 1) {
      adviceList.push(new Advice(`${this.plrDuNi} planerar att bo i detta boende endast under en kortare period`))
      adviceList.push(new Advice(`${this.plrDuNi} har möjlighet att extraamortera på rörliga lån`))
      return true
    }
    return false
  }
}
