import {CdkTextareaAutosize} from '@angular/cdk/text-field'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {Subscription} from 'rxjs'
import {debounceTime} from 'rxjs/operators'
import {StaticDataService} from '../../../services/static-data.service'

@Component({
  selector: 'spb-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  imports: [MatFormField, MatLabel, CdkTextareaAutosize, MatInput, ReactiveFormsModule]
})
export class CommentsComponent implements OnInit, OnDestroy {
  public comments = new FormControl()

  private reset$ = new Subscription()

  constructor(
    private staticDataService: StaticDataService
  ) {
  }

  public ngOnInit(): void {
    this.comments.valueChanges.pipe(
      debounceTime(1000)
    ).subscribe({
      next: (val: string) => {
        this.staticDataService.comment$.next(val)
      }
    })

    this.reset$ = this.staticDataService.comment$
      .subscribe({
        next: (comment: string) => {
          this.comments.setValue(comment, {emitEvent: false})
        }
      })
  }

  public ngOnDestroy(): void {
    this.reset$.unsubscribe()
  }
}
