import {Pipe, PipeTransform} from '@angular/core'
import {KalpLoan} from '../../model/kalp-loan'

@Pipe({
  name: 'loanFilter'
})
export class LoanFilterPipe implements PipeTransform {
  transform(loans: KalpLoan[] | null, blancoLoan: boolean): KalpLoan[] | null {
    if (loans) {
      return loans.filter((loan: KalpLoan) => loan.isBlancoLoan === blancoLoan)
    }
    return null
  }
}
