<form [formGroup]="form">
  <div class="row">
    <mat-form-field class="loan-amount">
      <mat-label>Belopp</mat-label>
      <input
        formControlName="amount"
        matInput
        name="loanAmount"
        spbFormatNumber>
      <span matTextSuffix>kr</span>
    </mat-form-field>
    <mat-form-field class="mortgage-amount">
      <mat-label>Amortering</mat-label>
      <input
        formControlName="mortgageAmount"
        matInput
        spbFormatNumber>
      <span matTextSuffix>kr/månad</span>
    </mat-form-field>
    <mat-form-field class="narrow">
      <mat-label>Ränta</mat-label>
      <input
        class="percent"
        formControlName="interestPercentString"
        matInput
        type="number">
      <span matTextSuffix>%</span>
    </mat-form-field>
  </div>

  <div class="row">
    @if (showTerms) {
      <mat-form-field>
        <mat-label>Räntebindning</mat-label>
        <mat-select formControlName="terms" ngDefaultControl>
          @for (term of termsTexts; track $index) {
            <mat-option [value]="term.terms">{{ term.text }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Villkorsändringsdag</mat-label>
        <input [matDatepicker]="picker" formControlName="expires" matInput>
        <mat-datepicker-toggle [for]="picker"
                               matIconSuffix></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    }
  </div>

  <div class="row">
    @if (properties.length > 0) {
      <mat-form-field class="property-select">
        <mat-label>Bostad</mat-label>
        @if (properties.length === 1) {
          <input formControlName="propertyName" matInput>
        } @else {
          <mat-select formControlName="property" ngDefaultControl>
            @for (property of properties; track $index) {
              <mat-option [value]="property.id">{{ property.name }}</mat-option>
            }
          </mat-select>
        }
      </mat-form-field>
    }

    @if (!conditions || (conditions && properties.length) > 0) {
      <mat-checkbox formControlName="solve" ngDefaultControl>
        @if (!conditions) {
          <span>Löses</span>
        }
        @if (conditions) {
          <span>Omsättning/Villkorsförändring</span>
        }
      </mat-checkbox>
    }
  </div>
</form>
