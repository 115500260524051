<h3>Kontaktuppgifter</h3>
<p>Låntagare</p>
<spb-email-and-phone (formStatusChanged)="onFormStatusChanged($event)"
                     [index]="0"></spb-email-and-phone>
@if (coApplicant) {
  <div>
    <p>Medlåntagare</p>
    <spb-email-and-phone (formStatusChanged)="onFormStatusChanged($event)"
                         [index]="1"></spb-email-and-phone>
  </div>
}
