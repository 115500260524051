@if (customerService.existingCustomers.has(personNummer) && router.url === '/main') {
  <div class="saver">
    <p>Det finns en sparad rådgivning på {{ personNummer }}</p>
    <button (click)="loadCustomer()" color="accent" mat-stroked-button
            type="button">Hämta?
    </button>
  </div>
}


