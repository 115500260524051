import {Routes} from '@angular/router'
import {MainComponent} from './0-main/main.component'
import {ResetComponent} from './reset/reset.component'

export default [
  {
    path: '',
    component: MainComponent
  },
  {
    path: ':id',
    component: MainComponent
  },
  {
    path: 'reset',
    component: ResetComponent
  }
] satisfies Routes
