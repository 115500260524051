import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {
  Applicant,
  Car,
  Child,
  Income,
  KalpDataItem,
  Loan,
  Property
} from '@sparbanken-syd/kalpylator'
import {EMPTY, Observable} from 'rxjs'
import {filter} from 'rxjs/operators'
import {DataStoreItem} from 'sparbanken-syd-datastore'
import {environment} from '../../environments/environment'
import {KalpBuilding} from '../model/kalp-building'
import {KalpLoan} from '../model/kalp-loan'
import {KalpylatorHelper} from '../model/kalpylator-helper'
import {DataService, SaveData, SaveDataIncome} from './data.service'

@Injectable({
  providedIn: 'root'
})
export class DatastoreService {

  constructor(
    private dataService: DataService,
    private httpClient: HttpClient
  ) {
  }


  public loadKalp(sub: string, applicantNumber: number) {
    this.getUser(sub).pipe(
      filter((dataStoreItems: DataStoreItem[]) => dataStoreItems.length > 0)
    ).subscribe({
      next: (dataStoreItems: DataStoreItem[]) => {
        const saveData = this.dataService.data$.value
        // Reset loans
        saveData.loans.otherMortgageLoans = []
        saveData.loans.privateLoans = []

        const kalpItems: KalpDataItem[] = dataStoreItems.map((item: DataStoreItem) => item.data)

        const applicants = KalpylatorHelper.getKalpItems<Applicant>(kalpItems, 'applicants')

        // An "applicants" for a specific sub can only be one item even if served as an array
        applicants.forEach((applicant: Applicant) => {
          saveData.applicants.applicants[applicantNumber] = saveData.applicants.applicants[applicantNumber] || {}
          Object.assign(saveData.applicants.applicants[applicantNumber], KalpylatorHelper.convertApplicantToLegacy(applicant))
        })

        const incomes: Income[] = KalpylatorHelper.getKalpItems<Income>(kalpItems, 'incomes')
        let storedIncome: Income = {
          applicantId: '',
          id: '',
          monthlyIncome: 0,
          taxFreeIncome: 0,
          type: 'incomes'
        }

        // Again we happen to know that there are just one.
        incomes.forEach((income: Income) => {
          // Set the personal incomes
          saveData.incomes.incomes[applicantNumber] = KalpylatorHelper.convertIncomeToLegacy<SaveDataIncome>(income)
          storedIncome = income
        })

        // If main applicant, also set the other items.
        if (applicantNumber === 0) {
          saveData.incomes.otherCosts = storedIncome.otherCosts

          // const children = kalpItems.filter((i: KalpDataItem) => i.type === 'children')
          const children = KalpylatorHelper.getKalpItems<Child>(kalpItems, 'children')
          saveData.applicants.childrenCount = children.length
          saveData.applicants.children = children.map((child: Child) => child.age ?? 0)

          this.setLoans(saveData, kalpItems)
          this.setProperties(saveData, kalpItems)
          this.setCars(saveData, kalpItems)

        }
        // We must emit since others need to know
        this.dataService.data$.next(saveData)
      },
      // If any error just ignore it.
      error: () => EMPTY
    })
  }

  /**
   * Get all items that belong to a user
   */
  private getUser(sub: string): Observable<DataStoreItem[]> {
    const url = `${environment.datastoreUrl}/user`
    return this.httpClient.post<DataStoreItem[]>(url, {sub})
  }

  private setLoans(saveData: SaveData, kalpItems: KalpDataItem[]): void {
    // We do not want the loan for the new building, in BR
    const savedLoans = KalpylatorHelper.getKalpItems<Loan>(kalpItems, 'loans')
      .filter((loan: Loan) => loan.propertyId !== 'new_property')


    const loans = savedLoans.map(loan => {
      const l = new KalpLoan(loan.amount)
      l.setLoanFromKalpInput(loan)
      return l
    })

    saveData.loans.otherMortgageLoans = loans.filter((loan: KalpLoan) => !loan.isBlancoLoan)
      .map((loan: KalpLoan) => DataService.loanFromLoan(loan))

    saveData.loans.privateLoans = loans.filter((loan: KalpLoan) => loan.isBlancoLoan)
      .map((loan: KalpLoan) => DataService.loanFromLoan(loan))
  }

  private setProperties(saveData: SaveData, kalpItems: KalpDataItem[]): void {
    const savedProperties = KalpylatorHelper.getKalpItems<Property>(kalpItems, 'properties')
    // There are either one or two buildings, the "primary" and possibly the "leave" building.
    const newBuilding = saveData.livings.buildings[0]
    const leaveBuilding = saveData.livings.buildings[1]

    savedProperties.forEach((property: Property) => {
      // The "primary"
      if (property.primary && newBuilding) {
        newBuilding.setBuildingFromKalpInput(property)
        saveData.livings.seekedAmount = property.loanAmount //seeked amount from KALP new_property
      }

      // If this is to be left, and we have a second building, this is the one
      // This must be _explicitly_ set to false. NB: Currently not supported
      // by BK Kalp
      if (property.keep === false && leaveBuilding) {
        leaveBuilding.setBuildingFromKalpInput(property)
      }
      // All other buildings/livings
      if (!property.primary) {
        const b = new KalpBuilding()
        b.setBuildingFromKalpInput(property)
        saveData.livings.buildings.push(b)
      }
    })
  }

  private setCars(saveData: SaveData, kalpItems: KalpDataItem[]): void {
    const cars = KalpylatorHelper.getKalpItems<Car>(kalpItems, 'cars')
    const leaseCars = cars.filter((c: Car) => c.carType === 'LEASE')
    const ownedCars = cars.filter((c: Car) => c.carType === 'OWN')

    if (leaseCars.length > 0) {
      saveData.applicants.haveLeaseCar = true
      if (leaseCars[0].monthlyCost !== undefined) {
        saveData.applicants.leaseCarCost = leaseCars[0].monthlyCost * leaseCars.length
      }
    }

    if (ownedCars.length > 0) {
      saveData.applicants.carCount = ownedCars.length
      saveData.applicants.haveCar = true
    }
  }
}
