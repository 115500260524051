import {Component, OnDestroy, OnInit} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {MatOption} from '@angular/material/core'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatSelect} from '@angular/material/select'
import {Subscription} from 'rxjs'
import {ControlService} from '../../services/control.service'
import {DataService} from '../../services/data.service'
import {KalpService, Scenarios} from '../../services/kalp.service'

@Component({
  selector: 'spb-scenario',
  templateUrl: './scenario.component.html',
  styleUrls: ['./scenario.component.scss'],
  imports: [MatFormField, MatLabel, MatSelect, ReactiveFormsModule, MatOption]
})
export class ScenarioComponent implements OnInit, OnDestroy {

  public scenariosCtrl = [Scenarios.Purchase, Scenarios.Increase, Scenarios.Transfer, Scenarios.Conditions]

  public scenario = new FormControl<Scenarios | null>(null)

  private scenario$ = new Subscription()

  constructor(
    private dataService: DataService,
    private kalpService: KalpService,
    private controlService: ControlService
  ) {
  }

  public ngOnInit(): void {

    this.scenario$ = this.kalpService.scenarioChange$.subscribe({
      next: (scenario: Scenarios | null) => {
        this.scenario.setValue(scenario, {emitEvent: false})
        this.controlService.scenarioSelected$.next(!!scenario)
      }
    })

    this.scenario.valueChanges.subscribe({
      next: (s: Scenarios | null) => {
        this.dataService.reset()
        this.kalpService.scenarioChange$.next(s)
        this.controlService.scenarioSelected$.next(true)
      }
    })
  }

  public ngOnDestroy(): void {
    this.scenario$.unsubscribe()
  }
}
